import { mapState, mapActions } from 'vuex'
import $ from 'jquery'
import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

import VueGoogleAnalytics from 'vue-analytics'
import VueGoogleAdsense from 'vue-google-adsense'
Vue.use(require('vue-script2'))

import VueCountryCode from "vue-country-code"
Vue.use(VueCountryCode)

import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'
Vue.use(VueTelInput)


import Print from 'vue-printjs'
Vue.use(Print)

import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import vSelect from 'vue-select'
Vue.component('v-select', vSelect)
import 'vue-select/dist/vue-select.css'

import VueMask from 'v-mask'
Vue.use(VueMask)

const moment = require('moment')
import 'moment/locale/es'
import 'moment/locale/pt-br'
import 'moment/locale/es-us'

import VueMoment from 'vue-moment'
Vue.use(VueMoment, { moment })

import VueIntercom from 'vue-intercom'
Vue.use(VueIntercom, { appId: process.env.INTERCOM_APP_ID })

import VueHtmlToPaper from 'vue-html-to-paper'
Vue.use(VueHtmlToPaper, {
    styles: [
        'https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css',
        'https://cdnjs.cloudflare.com/ajax/libs/bootstrap-vue/2.23.1/bootstrap-vue.min.css'
    ]
})

/*
  Slider range for the checkout
*/
import vuetify from '@/plugins/vuetify'

/*
  translation  
*/
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

import EN from '../public/assets/i18n/en'
import ES from '../public/assets/i18n/es'
import PT_BR from '../public/assets/i18n/pt_br'

var defaultLanguage
var browserLanguage = (navigator.browserLanguage != undefined) ? navigator.browserLanguage : navigator.language

if (browserLanguage.substr(0, 2) == 'pt') {
    defaultLanguage = 'pt_br'
} else if (browserLanguage.substr(0, 2) == 'es') {
    defaultLanguage = 'es'
} else {
    defaultLanguage = 'en'
}

moment.locale(defaultLanguage)


const numberFormats = {
    'usd': {
        currency: {
            style: 'currency',
            currency: 'USD'
        }
    },
    'brl': {
        currency: {
            style: 'currency',
            currency: 'BRL'
        }
    }
}

const i18n = new VueI18n({
    numberFormats,
    locale: defaultLanguage,
    fallbackLocale: 'pt_br',
    messages: {
        'en': {
            ...EN
        },
        'es': {
            ...ES
        },
        'pt_br': {
            ...PT_BR
        }
    },
    silentTranslationWarn: true
})

/*
  Components imported by the routes
*/

import Home from './components/home'
import Plans from './components/plans'
import Checkout from './components/plans/checkout'
import Ecm from './components/ecm'
import Bpm from './components/bpm'
import Slv from './components/slv'
import Das from './components/das'
import Dss from './components/dss'
import Swc from './components/swc'
import Cic from './components/cic'

import Blog from './components/blog'
import BlogPost from './components/blog/post'
import contractManagement from './components/process/contractManagement'
import customerService from './components/process/customerService'
import expensesRefund from './components/process/expensesRefund'
import paymentAuthorization from './components/process/paymentAuthorization'
import purchaseOrder from './components/process/purchaseOrder'
import recruitmentAndSelection from './components/process/recruitmentAndSelection'
import Solutions from './components/solutions'
import BecomeAPartner from './components/become-a-partner'
import AboutUs from './components/about-us'
import Careers from './components/careers'
import Agreement from './components/become-a-partner/Agreement'
import AgreementPrivateLabel from './components/become-a-partner/AgreementPrivateLabel'
import PrivacyPolicy from './components/privacy-policy'
import TermsAndConditions from './components/terms-and-conditions'
import NotFound from './components/NotFound'
import Thanks from './components/plans/checkout/Thanks'
import StartFree from './components/StartFree/index.vue'
import StartFreeCheckout from './components/StartFree/checkout.vue'
// import VueFacebookPixel from 'vue-facebook-pixel'
// Vue.use(VueFacebookPixel)


/*
  Routes
*/

const propsHandler = (route) => {
    // console.log(">> Route props", route.params)
    return true
}

var routes = [{
    path: "*",
    name: "NotFound",
    component: NotFound,
},
{
    path: "/nomedoparceiro",
    name: "home-fake",
    props: propsHandler,
    component: Home,
    meta: {
        title: {
            en: '{title}',
            pt: '{title}',
            es: '{title}'
        },
        metaTags: [{
            name: 'keywords',
            content: 'ecm,bpm,crm,erp,swc,das,slv,{title}'
        },
        {
            name: 'author',
            content: '{company_fullname}'
        },
        {
            name: 'description',
            content: {
                en: 'Home - {title}',
                pt: 'Home - {title}',
                es: 'Home - {title}'
            }
        },
        {
            property: 'og:description',
            content: {
                en: 'Home - {title}',
                pt: 'Home - {title}',
                es: 'Home - {title}'
            }
        }
        ]
    }
},
{
    path: "/",
    name: "home",
    props: propsHandler,
    component: Home,
    meta: {
        title: {
            en: '{title}',
            pt: '{title}',
            es: '{title}'
        },
        metaTags: [{
            name: 'keywords',
            content: 'ecm,bpm,crm,erp,swc,das,slv,{title}'
        },
        {
            name: 'author',
            content: '{company_fullname}'
        },
        {
            name: 'description',
            content: {
                en: 'Home - {title}',
                pt: 'Home - {title}',
                es: 'Home - {title}'
            }
        },
        {
            property: 'og:description',
            content: {
                en: 'Home - {title}',
                pt: 'Home - {title}',
                es: 'Home - {title}'
            }
        }
        ]
    }
},
{
    path: "/home",
    name: "home-2",
    props: propsHandler,
    component: Home,
    meta: {
        title: {
            en: '{title}',
            pt: '{title}',
            es: '{title}'
        },
        metaTags: [{
            name: 'keywords',
            content: 'ecm,bpm,crm,erp,swc,das,slv,{title}'
        },
        {
            name: 'author',
            content: '{company_fullname}'
        },
        {
            name: 'description',
            content: {
                en: 'Home - {title}',
                pt: 'Home - {title}',
                es: 'Home - {title}'
            }
        },
        {
            property: 'og:description',
            content: {
                en: 'Home - {title}',
                pt: 'Home - {title}',
                es: 'Home - {title}'
            }
        }
        ]
    }
},
{
    path: "/agreement/:agreementType/:lang?/:data?",
    name: "agreement",
    props: propsHandler,
    component: Agreement,
    meta: {
        title: {
            en: 'Agreement - {title}',
            pt: 'Contrato - {title}',
            es: 'Contracto - {title}'
        },
        metaTags: [{
            name: 'keywords',
            content: 'ecm,bpm,crm,erp,swc,das,slv,{title}'
        },
        {
            name: 'author',
            content: '{company_fullname}'
        },
        {
            name: 'description',
            content: {
                en: 'Agreement - {title}',
                pt: 'Contrato - {title}',
                es: 'Contracto - {title}'
            }
        },
        {
            property: 'og:description',
            content: {
                en: 'Agreement - {title}',
                pt: 'Contrato - {title}',
                es: 'Contracto - {title}'
            }
        }
        ]
    }
},

{
    path: "/agreement-private-label/:lang?/:data?",
    redirect: to => {
        let lang = to.params.lang
        let data = to.params.data

        let newPath = '/agreement/private_label'
        if (lang) {
            newPath += '/' + lang
        }

        if (!lang && data) {
            newPath += '/en/' + data
        } else if (data) {
            newPath += '/' + data
        }
        
        return { path: newPath }
    },
},


{
    path: "/privacy-policy",
    name: "privacy-policy",
    props: propsHandler,
    component: PrivacyPolicy,
    meta: {
        title: {
            en: 'Privacy Policy - {title}',
            pt: 'Política de Privacidade - {title}',
            es: 'Política de privacidad - {title}'
        },
        metaTags: [{
            name: 'keywords',
            content: 'ecm,bpm,crm,erp,swc,das,slv,{title}'
        },
        {
            name: 'author',
            content: '{company_fullname}'
        },
        {
            name: 'description',
            content: {
                en: 'Privacy Policy - {title}',
                pt: 'Política de Privacidade - {title}',
                es: 'Política de privacidad - {title}'
            }
        },
        {
            property: 'og:description',
            content: {
                en: 'Privacy Policy - {title}',
                pt: 'Política de Privacidade - {title}',
                es: 'Política de privacidad - {title}'
            }
        }
        ]
    }
},
{
    path: "/privacy-policy/:lang",
    name: "privacy-policy",
    props: propsHandler,
    component: PrivacyPolicy,
    meta: {
        title: {
            en: 'Privacy Policy - {title}',
            pt: 'Política de Privacidade - {title}',
            es: 'Política de privacidad - {title}'
        },
        metaTags: [{
            name: 'keywords',
            content: 'ecm,bpm,crm,erp,swc,das,slv,{title}'
        },
        {
            name: 'author',
            content: '{company_fullname}'
        },
        {
            name: 'description',
            content: {
                en: 'Privacy Policy - {title}',
                pt: 'Política de Privacidade - {title}',
                es: 'Política de privacidad - {title}'
            }
        },
        {
            property: 'og:description',
            content: {
                en: 'Privacy Policy - {title}',
                pt: 'Política de Privacidade - {title}',
                es: 'Política de privacidad - {title}'
            }
        }
        ]
    }
},
{
    path: "/terms-and-conditions",
    name: "terms-and-conditions",
    props: propsHandler,
    component: TermsAndConditions,
    meta: {
        title: {
            en: 'Terms and Conditions - {title}',
            pt: 'Termos de Uso - {title}',
            es: 'Terminos de Uso - {title}'
        },
        metaTags: [{
            name: 'keywords',
            content: 'ecm,bpm,crm,erp,swc,das,slv,{title}'
        },
        {
            name: 'author',
            content: '{company_fullname}'
        },
        {
            name: 'description',
            content: {
                en: 'Terms and Conditions - {title}',
                pt: 'Termos de Uso - {title}',
                es: 'Terminos de Uso - {title}'
            }
        },
        {
            property: 'og:description',
            content: {
                en: 'Terms and Conditions - {title}',
                pt: 'Termos de Uso - {title}',
                es: 'Terminos de Uso - {title}'
            }
        }
        ]
    }
},
{
    path: "/terms-and-conditions/:lang",
    name: "terms-and-conditions",
    props: propsHandler,
    component: TermsAndConditions,
    meta: {
        title: {
            en: 'Terms and Conditions - {title}',
            pt: 'Termos de Uso - {title}',
            es: 'Terminos de Uso - {title}'
        },
        metaTags: [{
            name: 'keywords',
            content: 'ecm,bpm,crm,erp,swc,das,slv,{title}'
        },
        {
            name: 'author',
            content: '{company_fullname}'
        },
        {
            name: 'description',
            content: {
                en: 'Terms and Conditions - {title}',
                pt: 'Termos de Uso - {title}',
                es: 'Terminos de Uso - {title}'
            }
        },
        {
            property: 'og:description',
            content: {
                en: 'Terms and Conditions - {title}',
                pt: 'Termos de Uso - {title}',
                es: 'Terminos de Uso - {title}'
            }
        }
        ]
    }
},
{
    path: "/plans/checkout/:data",
    name: "checkout",
    props: propsHandler,
    component: Checkout,
    meta: {
        title: {
            en: 'Checkout - {title}',
            pt: 'Checkout - {title}',
            es: 'Checkout - {title}'
        },
        metaTags: [{
            name: 'keywords',
            content: 'ecm,bpm,crm,erp,swc,das,slv,{title}'
        },
        {
            name: 'author',
            content: '{company_fullname}'
        },
        {
            name: 'description',
            content: {
                en: 'Checkout - {title}',
                pt: 'Checkout - {title}',
                es: 'Checkout - {title}'
            }
        },
        {
            property: 'og:description',
            content: {
                en: 'Checkout - {title}',
                pt: 'Checkout - {title}',
                es: 'Checkout - {title}'
            }
        }
        ]
    }
},
{
    path: "/plans/checkout/thank-you/:data",
    name: "thanks-page",
    props: propsHandler,
    component: Thanks,
    meta: {
        title: {
            en: 'Thank You - {title}',
            pt: 'Obrigado - {title}',
            es: 'Gracias - {title}'
        },
        metaTags: [{
            name: 'keywords',
            content: 'ecm,bpm,crm,erp,swc,das,slv,{title}'
        },
        {
            name: 'author',
            content: '{company_fullname}'
        },
        {
            name: 'description',
            content: {
                en: 'Thank You - {title}',
                pt: 'Obrigado - {title}',
                es: 'Gracias - {title}'
            }
        },
        {
            property: 'og:description',
            content: {
                en: 'Thank You - {title}',
                pt: 'Obrigado - {title}',
                es: 'Gracias - {title}'
            }
        }
        ]
    }
},
{
    path: "/ecm",
    name: "ecm",
    props: propsHandler,
    component: Ecm,
    meta: {
        title: {
            en: 'ECM - {title}',
            pt: 'ECM - {title}',
            es: 'ECM - {title}'
        },
        metaTags: [{
            name: 'keywords',
            content: 'ecm,bpm,crm,erp,swc,das,slv,{title}'
        },
        {
            name: 'author',
            content: '{company_fullname}'
        },
        {
            name: 'description',
            content: {
                en: 'ECM - {title}',
                pt: 'ECM - {title}',
                es: 'ECM - {title}'
            }
        },
        {
            property: 'og:description',
            content: {
                en: 'ECM - {title}',
                pt: 'ECM - {title}',
                es: 'ECM - {title}'
            }
        }
        ]
    }
},
{
    path: "/bpm",
    name: "bpm",
    props: propsHandler,
    component: Bpm,
    meta: {
        title: {
            en: 'BPM - {title}',
            pt: 'BPM - {title}',
            es: 'BPM - {title}'
        },
        metaTags: [{
            name: 'keywords',
            content: 'ecm,bpm,crm,erp,swc,das,slv,{title}'
        },
        {
            name: 'author',
            content: '{company_fullname}'
        },
        {
            name: 'description',
            content: {
                en: 'BPM - {title}',
                pt: 'BPM - {title}',
                es: 'BPM - {title}'
            }
        },
        {
            property: 'og:description',
            content: {
                en: 'BPM - {title}',
                pt: 'BPM - {title}',
                es: 'BPM - {title}'
            }
        }
        ]
    }
},
{
    path: "/swc",
    name: "swc",
    props: propsHandler,
    component: Swc,
    meta: {
        title: {
            en: 'SWC - {title}',
            pt: 'SWC - {title}',
            es: 'SWC - {title}'
        },
        metaTags: [{
            name: 'keywords',
            content: 'ecm,bpm,crm,erp,swc,das,slv,{title}'
        },
        {
            name: 'author',
            content: '{company_fullname}'
        },
        {
            name: 'description',
            content: {
                en: 'SWC - {title}',
                pt: 'SWC - {title}',
                es: 'SWC - {title}'
            }
        },
        {
            property: 'og:description',
            content: {
                en: 'SWC - {title}',
                pt: 'SWC - {title}',
                es: 'SWC - {title}'
            }
        }
        ]
    }
},
{
    path: "/dss",
    name: "dss",
    props: propsHandler,
    component: Dss,
    meta: {
        title: {
            en: 'DSS - {title}',
            pt: 'DSS - {title}',
            es: 'DSS - {title}'
        },
        metaTags: [{
            name: 'keywords',
            content: 'ecm,bpm,crm,erp,swc,das,slv,{title}'
        },
        {
            name: 'author',
            content: '{company_fullname}'
        },
        {
            name: 'description',
            content: {
                en: 'DSS - {title}',
                pt: 'DSS - {title}',
                es: 'DSS - {title}'
            }
        },
        {
            property: 'og:description',
            content: {
                en: 'DSS - {title}',
                pt: 'DSS - {title}',
                es: 'DSS - {title}'
            }
        }
        ]
    }
},
{
    path: "/slv",
    name: "slv",
    props: propsHandler,
    component: Slv,
    meta: {
        title: {
            en: 'SLV - {title}',
            pt: 'SLV - {title}',
            es: 'SLV - {title}'
        },
        metaTags: [{
            name: 'keywords',
            content: 'ecm,bpm,crm,erp,swc,das,slv,{title}'
        },
        {
            name: 'author',
            content: '{company_fullname}'
        },
        {
            name: 'description',
            content: {
                en: 'SLV - {title}',
                pt: 'SLV - {title}',
                es: 'SLV - {title}'
            }
        },
        {
            property: 'og:description',
            content: {
                en: 'SLV - {title}',
                pt: 'SLV - {title}',
                es: 'SLV - {title}'
            }
        }
        ]
    }
},
{
    path: "/das",
    name: "das",
    props: propsHandler,
    component: Das,
    meta: {
        title: {
            en: 'DAS - {title}',
            pt: 'DAS - {title}',
            es: 'DAS - {title}'
        },
        metaTags: [{
            name: 'keywords',
            content: 'ecm,bpm,crm,erp,swc,das,slv,{title}'
        },
        {
            name: 'author',
            content: '{company_fullname}'
        },
        {
            name: 'description',
            content: {
                en: 'DAS - {title}',
                pt: 'DAS - {title}',
                es: 'DAS - {title}'
            }
        },
        {
            property: 'og:description',
            content: {
                en: 'DAS - {title}',
                pt: 'DAS - {title}',
                es: 'DAS - {title}'
            }
        }
        ]
    }
},
{
    path: "/blog",
    name: "blog",
    props: propsHandler,
    component: Blog,
    meta: {
        title: {
            en: 'Blog - {title}',
            pt: 'Blog - {title}',
            es: 'Blog - {title}'
        },
        metaTags: [{
            name: 'keywords',
            content: 'ecm,bpm,crm,erp,swc,das,slv,{title}'
        },
        {
            name: 'author',
            content: '{company_fullname}'
        },
        {
            name: 'description',
            content: {
                en: 'Blog - {title}',
                pt: 'Blog - {title}',
                es: 'Blog - {title}'
            }
        },
        {
            property: 'og:description',
            content: {
                en: 'Blog - {title}',
                pt: 'Blog - {title}',
                es: 'Blog - {title}'
            }
        }
        ]
    }
},
{
    path: "/cic",
    name: "cic",
    props: propsHandler,
    component: Cic,
    meta: {
        title: {
            en: 'Cic - {title}',
            pt: 'Cic - {title}',
            es: 'Cic - {title}'
        },
        metaTags: [{
            name: 'keywords',
            content: 'ecm,bpm,crm,erp,swc,das,slv,{title}'
        },
        {
            name: 'author',
            content: '{company_fullname}'
        },
        {
            name: 'description',
            content: {
                en: 'Cic - {title}',
                pt: 'Cic - {title}',
                es: 'Cic - {title}'
            }
        },
        {
            property: 'og:description',
            content: {
                en: 'Cic - {title}',
                pt: 'Cic - {title}',
                es: 'Cic - {title}'
            }
        }
        ]
    }
},
{
    path: "/blog/:id/:slug",
    name: "blog-post",
    props: propsHandler,
    component: BlogPost,
    meta: {
        title: {
            en: '{post_title} - {title}',
            pt: '{post_title} - {title}',
            es: '{post_title} - {title}'
        },
        metaTags: [{
            name: 'keywords',
            content: 'ecm,bpm,crm,erp,swc,das,slv,{title}'
        },
        {
            name: 'author',
            content: '{company_fullname}'
        },
        {
            name: 'description',
            content: {
                en: '{post_title} - {title}',
                pt: '{post_title} - {title}',
                es: '{post_title} - {title}'
            }
        },
        {
            property: 'og:description',
            content: {
                en: '{post_title} - {title}',
                pt: '{post_title} - {title}',
                es: '{post_title} - {title}'
            }
        }
        ]
    }
},
{
    path: "/process/contractManagement",
    name: "process-contractManagement",
    props: propsHandler,
    component: contractManagement,
    meta: {
        title: {
            en: 'Process - {title}',
            pt: 'Processo - {title}',
            es: 'Processo - {title}'
        },
        metaTags: [{
            name: 'keywords',
            content: 'ecm,bpm,crm,erp,swc,das,slv,{title}'
        },
        {
            name: 'author',
            content: '{company_fullname}'
        },
        {
            name: 'description',
            content: {
                en: 'Process - {title}',
                pt: 'Processo - {title}',
                es: 'Processo - {title}'
            }
        },
        {
            property: 'og:description',
            content: {
                en: 'Process - {title}',
                pt: 'Processo - {title}',
                es: 'Processo - {title}'
            }
        }
        ]
    }
},

{
    path: "/process/customerService",
    name: "process-customerService",
    props: propsHandler,
    component: customerService,
    meta: {
        title: {
            en: 'Process - {title}',
            pt: 'Processo - {title}',
            es: 'Processo - {title}'
        },
        metaTags: [{
            name: 'keywords',
            content: 'ecm,bpm,crm,erp,swc,das,slv,{title}'
        },
        {
            name: 'author',
            content: '{company_fullname}'
        },
        {
            name: 'description',
            content: {
                en: 'Process - {title}',
                pt: 'Processo - {title}',
                es: 'Processo - {title}'
            }
        },
        {
            property: 'og:description',
            content: {
                en: 'Process - {title}',
                pt: 'Processo - {title}',
                es: 'Processo - {title}'
            }
        }
        ]
    }
},

{
    path: "/process/expensesRefund",
    name: "process-expensesRefund",
    props: propsHandler,
    component: expensesRefund,
    meta: {
        title: {
            en: 'Process - {title}',
            pt: 'Processo - {title}',
            es: 'Processo - {title}'
        },
        metaTags: [{
            name: 'keywords',
            content: 'ecm,bpm,crm,erp,swc,das,slv,{title}'
        },
        {
            name: 'author',
            content: '{company_fullname}'
        },
        {
            name: 'description',
            content: {
                en: 'Process - {title}',
                pt: 'Processo - {title}',
                es: 'Processo - {title}'
            }
        },
        {
            property: 'og:description',
            content: {
                en: 'Process - {title}',
                pt: 'Processo - {title}',
                es: 'Processo - {title}'
            }
        }
        ]
    }
},

{
    path: "/process/paymentAuthorization",
    name: "process-paymentAuthorization",
    props: propsHandler,
    component: paymentAuthorization,
    meta: {
        title: {
            en: 'Process - {title}',
            pt: 'Processo - {title}',
            es: 'Processo - {title}'
        },
        metaTags: [{
            name: 'keywords',
            content: 'ecm,bpm,crm,erp,swc,das,slv,{title}'
        },
        {
            name: 'author',
            content: '{company_fullname}'
        },
        {
            name: 'description',
            content: {
                en: 'Process - {title}',
                pt: 'Processo - {title}',
                es: 'Processo - {title}'
            }
        },
        {
            property: 'og:description',
            content: {
                en: 'Process - {title}',
                pt: 'Processo - {title}',
                es: 'Processo - {title}'
            }
        }
        ]
    }
},

{
    path: "/process/purchaseOrder",
    name: "process-purchaseOrder",
    props: propsHandler,
    component: purchaseOrder,
    meta: {
        title: {
            en: 'Process - {title}',
            pt: 'Processo - {title}',
            es: 'Processo - {title}'
        },
        metaTags: [{
            name: 'keywords',
            content: 'ecm,bpm,crm,erp,swc,das,slv,{title}'
        },
        {
            name: 'author',
            content: '{company_fullname}'
        },
        {
            name: 'description',
            content: {
                en: 'Process - {title}',
                pt: 'Processo - {title}',
                es: 'Processo - {title}'
            }
        },
        {
            property: 'og:description',
            content: {
                en: 'Process - {title}',
                pt: 'Processo - {title}',
                es: 'Processo - {title}'
            }
        }
        ]
    }
},

{
    path: "/process/recruitmentAndSelection",
    name: "process-recruitmentAndSelection",
    props: propsHandler,
    component: recruitmentAndSelection,
    meta: {
        title: {
            en: 'Process - {title}',
            pt: 'Processo - {title}',
            es: 'Processo - {title}'
        },
        metaTags: [{
            name: 'keywords',
            content: 'ecm,bpm,crm,erp,swc,das,slv,{title}'
        },
        {
            name: 'author',
            content: '{company_fullname}'
        },
        {
            name: 'description',
            content: {
                en: 'Process - {title}',
                pt: 'Processo - {title}',
                es: 'Processo - {title}'
            }
        },
        {
            property: 'og:description',
            content: {
                en: 'Process - {title}',
                pt: 'Processo - {title}',
                es: 'Processo - {title}'
            }
        }
        ]
    }
},
{
    path: "/about-us",
    name: "about-us",
    props: propsHandler,
    component: AboutUs,
    meta: {
        title: {
            en: 'About Us - {title}',
            pt: 'Sobre Nós - {title}',
            es: 'Sobre Nosotros - {title}'
        },
        metaTags: [{
            name: 'keywords',
            content: 'ecm,bpm,crm,erp,swc,das,slv,{title}'
        },
        {
            name: 'author',
            content: '{company_fullname}'
        },
        {
            name: 'description',
            content: {
                en: 'About Us - {title}',
                pt: 'Sobre Nós - {title}',
                es: 'Sobre Nosotros - {title}'
            }
        },
        {
            property: 'og:description',
            content: {
                en: 'About Us - {title}',
                pt: 'Sobre Nós - {title}',
                es: 'Sobre Nosotros - {title}'
            }
        }
        ]
    }
},
{
    path: "/careers",
    name: "careers",
    props: propsHandler,
    component: Careers,
    meta: {
        title: {
            en: 'Careers - {title}',
            pt: 'Carreiras - {title}',
            es: 'Carreras - {title}'
        },
        metaTags: [{
            name: 'keywords',
            content: 'ecm,bpm,crm,erp,swc,das,slv,{title}'
        },
        {
            name: 'author',
            content: '{company_fullname}'
        },
        {
            name: 'description',
            content: {
                en: 'Careers - {title}',
                pt: 'Carreiras - {title}',
                es: 'Carreras - {title}'
            }
        },
        {
            property: 'og:description',
            content: {
                en: 'Careers - {title}',
                pt: 'Carreiras - {title}',
                es: 'Carreras - {title}'
            }
        }
        ]
    }
},
{
    path: "/solutions",
    name: "solutions",
    props: propsHandler,
    component: Solutions,
    meta: {
        title: {
            en: 'Solutions - {title}',
            pt: 'Soluções - {title}',
            es: 'Soluciones - {title}'
        },
        metaTags: [{
            name: 'keywords',
            content: 'ecm,bpm,crm,erp,swc,das,slv,{title}'
        },
        {
            name: 'author',
            content: '{company_fullname}'
        },
        {
            name: 'description',
            content: {
                en: 'Solutions - {title}',
                pt: 'Soluções - {title}',
                es: 'Soluciones - {title}'
            }
        },
        {
            property: 'og:description',
            content: {
                en: 'Solutions - {title}',
                pt: 'Soluções - {title}',
                es: 'Soluciones - {title}'
            }
        }
        ]
    }
},
{
    path: "/become-a-partner",
    name: "become-a-partner",
    props: propsHandler,
    component: BecomeAPartner,
    meta: {
        title: {
            en: 'Become a Partner - {title}',
            pt: 'Seja um Parceiro - {title}',
            es: 'Sea un socio - {title}'
        },
        metaTags: [{
            name: 'keywords',
            content: 'ecm,bpm,crm,erp,swc,das,slv,{title}'
        },
        {
            name: 'author',
            content: '{company_fullname}'
        },
        {
            name: 'description',
            content: {
                en: 'Become a Partner - {title}',
                pt: 'Seja um Parceiro - {title}',
                es: 'Sea un Socio - {title}'
            }
        },
        {
            property: 'og:description',
            content: {
                en: 'Become a Partner - {title}',
                pt: 'Seja um Parceiro - {title}',
                es: 'Sea un Socio - {title}'
            }
        }
        ]
    }
},
{
    path: "/start-free",
    name: "start-free",
    props: propsHandler,
    component: StartFree,
    meta: {
        title: {
            en: 'Start Free - {title}',
            pt: 'Comece Grátis - {title}',
            es: 'Empezar Gratis - {title}'
        },
        metaTags: [{
            name: 'keywords',
            content: 'ecm,bpm,crm,erp,swc,das,slv,{title}'
        },
        {
            name: 'author',
            content: '{company_fullname}'
        },
        {
            name: 'description',
            content: {
                en: 'Start Free - {title}',
                pt: 'Comece Grátis - {title}',
                es: 'Empezar Gratis - {title}'
            }
        },
        {
            property: 'og:description',
            content: {
                en: 'Start Free - {title}',
                pt: 'Comece Grátis - {title}',
                es: 'Empezar Gratis - {title}'
            }
        }
        ]
    }
},
{
    path: "/start-free/checkout/:data",
    name: "start-free-checkout",
    props: propsHandler,
    component: StartFreeCheckout,
    meta: {
        title: {
            en: 'Start Free - {title}',
            pt: 'Comece Grátis - {title}',
            es: 'Empezar Gratis - {title}'
        },
        metaTags: [{
            name: 'keywords',
            content: 'ecm,bpm,crm,erp,swc,das,slv,{title}'
        },
        {
            name: 'author',
            content: '{company_fullname}'
        },
        {
            name: 'description',
            content: {
                en: 'Start Free - {title}',
                pt: 'Comece Grátis - {title}',
                es: 'Empezar Gratis - {title}'
            }
        },
        {
            property: 'og:description',
            content: {
                en: 'Start Free - {title}',
                pt: 'Comece Grátis - {title}',
                es: 'Empezar Gratis - {title}'
            }
        }
        ]
    }
},
{
    path: "/plans",
    name: "plans",
    props: propsHandler,
    component: Plans,
    meta: {
        title: {
            en: 'Plans - {title}',
            pt: 'Planos - {title}',
            es: 'Planes - {title}'
        },
        metaTags: [{
            name: 'keywords',
            content: 'ecm,bpm,crm,erp,swc,das,slv,{title}'
        },
        {
            name: 'author',
            content: '{company_fullname}'
        },
        {
            name: 'description',
            content: {
                en: 'Plans - {title}',
                pt: 'Planos - {title}',
                es: 'Planes - {title}'
            }
        },
        {
            property: 'og:description',
            content: {
                en: 'Plans - {title}',
                pt: 'Planos - {title}',
                es: 'Planes - {title}'
            }
        }
        ]
    }
},
{
    path: "/plans/restricted/currency/:currency_by_url",
    name: "plans_currency_by_url",
    props: propsHandler,
    component: Plans,
    meta: {
        title: {
            en: 'Plans - {title}',
            pt: 'Planos - {title}',
            es: 'Planes - {title}'
        },
        metaTags: [{
            name: 'keywords',
            content: 'ecm,bpm,crm,erp,swc,das,slv,{title}'
        },
        {
            name: 'author',
            content: '{company_fullname}'
        },
        {
            name: 'description',
            content: {
                en: 'Plans - {title}',
                pt: 'Planos - {title}',
                es: 'Planes - {title}'
            }
        },
        {
            property: 'og:description',
            content: {
                en: 'Plans - {title}',
                pt: 'Planos - {title}',
                es: 'Planes - {title}'
            }
        }
        ]
    }
},

]

const urlPattern = /\/partner\/(\d+)\/([^/]+)$/;
const currentUrl = window.location.href;

if (urlPattern.test(currentUrl)) {
    console.debug('found url with partner_id, rewriting url');
    const newUrl = currentUrl.replace(urlPattern, '/partner/$2');
    window.history.replaceState(null, '', newUrl);
}

var partner_url = "/partner/:partner_slug"
var routesProps = []
for (var i in routes) {
    if (routes[i].name != 'NotFound' && routes[i].name != 'checkout' && routes[i].name != 'thanks-page') {
        let route = { ...routes[i] }

        if (routes[i].name == 'home') {
            route.path = partner_url
        } else {
            route.path += partner_url
        }

        route.name += "-partner"

        routesProps.push(route)
    }
}

routes = [...routes, ...routesProps]

const router = new VueRouter({
    routes,
    mode: 'history',
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
})

router.beforeEach((to, from, next) => {

    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)
    const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)

    const lang = i18n.locale == "pt_br" ? 'pt' : i18n.locale

    if (nearestWithTitle) document.title = nearestWithTitle.meta.title[lang].replace(" - {title}", "").replace("{title}", "")

    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el))

    next()
})

router.afterEach((to, from) => {

    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title),
        nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags),
        previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)

    Vue.analytics.fbq.init('1029797980716902')
    Vue.analytics.fbq.event('track', 'PageView')

    console.log(">> Facebook pixel loaded")

});


Vue.mixin({
    methods: {
        setGoogleCookies(domain) {

            if (domain.googleanalytics_key && domain.googleanalytics_key != '') {
                Vue.use(VueGoogleAnalytics, {
                    id: domain.googleanalytics_key,
                    router
                })
                console.log(">> Google analytics defined")//, domain.googleanalytics_key)
            }


            if (domain.googleads_key && domain.googleads_key != '') {
                Vue.use(VueGoogleAdsense.AutoAdsense, {
                    adClient: domain.googleads_key
                })
                console.log(">> Google adSense defined")//, domain.googleads_key)
            }

        },
    }
})



Vue.config.productionTip = false

new Vue({
    i18n,
    store,
    router,
    vuetify,
    render: h => h(App),
}).$mount('#app')