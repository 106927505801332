import $ from 'jquery'
import axios from 'axios'

const state = {
    posts_list: [],
    posts_authors: [],
    posts_categories: [],
    post: {},
    shopping_cart: {
        selected_plan: null,
        selected_addons: null,
        payment: null,
        account: null
    },
    partner: null,
    pre_customer: null,
    customer: null,
    workspace: null,
    locale: null,
    plans: [],
    addons: [],
    default_workspace: 'interfy-dev',
    env: process.env.env,
    api: {
        token: null,
        baseUrl: process.env.api_url,
        login: {
            link: "/oauth/token",
            client_id: process.env.login_client_id,
            client_secret: process.env.login_client_secret,
            grant_type: process.env.login_grant_type,
            password: process.env.login_password,
            scope: process.env.login_scope,
            username: process.env.login_username,
        },
        customers: {
            link: "/api/v1/site/customers",
            savePreCustomer: "/api/v1/site/pre_customers",
            editPreCustomer: "/api/v1/site/pre_customers/{pre_customer}",
            setEmailConfirmed: "/api/v1/site/pre_customers/{pre_customer}/setEmailConfirmed",
            getPreCustomer: "/api/v1/site/pre_customers/{pre_customer}/email/{email}",
            getPreCustomerByEmail: "/api/v1/site/pre_customers/getPreCustomerByEmail"
        },
        partners: {
            link: "/api/v1/site/partners",
            getPartnersUsers: "/api/v1/site/partners/getPartnersUsers",
            getPartnerBySlug: "/api/v1/site/partners/getPartnerBySlug",
            savePrePartner: "/api/v1/site/pre_partners",
            editPrePartner: "/api/v1/site/pre_partners/{pre_partner}",
            getPrePartnerByEmail: "/api/v1/site/pre_partners/getPrePartnerByEmail"
        },

        workspaces: {
            link: "/api/v1/site/workspaces",
        },
        posts: {
            link: "/api/v1/site/posts",
            getPost: "/api/v1/site/posts/{post}",
            getPostsCategories: "/api/v1/site/posts/categories",
            getPostsAuthors: "/api/v1/site/posts/authors",
        },
        misc: {
            getCepData: "/api/v1/site/cep",
        },
        plans: {
            link: "/api/v1/site/plans",
            getSellablePlans: "/api/v1/site/plans/getSellablePlans",
            getAddons: "/api/v1/site/addons/status/1/field/order/asc",
            getAddonByKey: "/api/v1/site/addons/addon-by-key"
        },
        notification: {
            newsletter: "/api/v1/site/notification/newsletter",
            careers: "/api/v1/site/notification/careers",
            partnership: "/api/v1/site/notification/partnership",
            emailConfirmation: "/api/v1/site/notification/emailConfirmation",
            workspace_created: "/api/v1/site/notification/workspaceCreated",
        },
        upload: "/api/v1/site/upload",
        domains: {
            link: "/api/v1/site/domains",
            getDomainByLocation: "/api/v1/site/domains/getDomainByLocation"
        },
    },
    stripe: {
        publishableKey: process.env.stripe_publishableKey
    },
    google: {
        recaptcha: {
            site_key: process.env.google_recaptcha_site_key,
            secret_key: process.env.google_recaptcha_secret_key
        }
    },
    domain: null,
    user_monetary: null,
    user_ip: null
}

let monetaries = {
    'usa': {
        ioc: "us",
        symbol: "$",
        currency_code: "usd",
        separators: {
            cents: ".",
            other: ","
        }
    },
    'bra': {
        ioc: "br",
        symbol: "R$",
        currency_code: "brl",
        separators: {
            cents: ",",
            other: "."
        }
    }
}


let defaultLocale = "usd",
    defaultUserMonetary = {}

if (defaultLocale == 'bra') {
    defaultUserMonetary = monetaries.bra
} else {
    defaultUserMonetary = monetaries.usa
}



var defaultDomain = {
    languages: "[\"pt_br\",\"en\",\"es\"]",
    type: 'interfy',
    name: 'interfy',
    title: 'Interfy',
    location: 'interfy.io',
    favicon: 'https://interfy.s3.amazonaws.com/domains/interfy/favicon.ico',
    logo: 'https://interfy.s3.amazonaws.com/domains/interfy/logo.png',
    minilogo: 'https://interfy.s3.amazonaws.com/domains/interfy/minilogo.png',
    wallpaper: 'https://interfy.s3.amazonaws.com/domains/interfy/wallpaper.jpg',
    wallpaper404: '',
    company_name: 'INTERFY',
    company_fullname: 'Interfy Internacional LLC',
    email1: 'info@interfy.io',
    email2: 'support@interfy.io',
    address1: '6000 MetroWest Blvd., S203',
    address2: 'Orlando - 32835 - USA',
    googlemaps1: 'https://goo.gl/maps/FxWQbgyKV4CeNU1M7',
    googlemaps2: '',
    //localhost
    googlerecaptcha_key: '6LdXbPYUAAAAAN7Lvir_zEThIYHcVCHHWG8KpJfT',
    //localhost
    googlerecaptcha_secret: '6LdXbPYUAAAAAD-D4Pkjl2OrFvWilnCfsAJvADm0',
    googleanalytics_key: 'UA-135248849-1',
    googleads_key: 'AW-719000317',
    phone1: '+1 (786) 353-5568',
    phone2: '+55 (11) 5929-8900',
    instagram: 'https://www.instagram.com/interfy_corp/',
    facebook: 'https://www.facebook.com/interfycorporation/',
    linkedin: 'https://www.linkedin.com/company/interfy',
    youtube: 'https://www.youtube.com/channel/UC_l7JI7jdIChIdGRlSAd2tg'
}

const actions = {

    async enableGoogleAnalytics() {
        // var googleAnalyticsId = await loadGtmId("GTM-PMZ5J5NS");  // GTM-XXXXXXX
        // this.activateGtm(window, document, 'script', 'dataLayer', "GTM-PMZ5J5NS");
    },

    activateGtm(w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
    },

    async setUserMonetary({ commit }, payload) {

        axios.get('/api/getUserIp').then(response => {
            const userData = response.data
            commit('MUTATE_USER_IP', userData.user_ip)
        })

        console.log(">> Setting user monetary...")

        if (payload && payload.currency_by_url) {

            const currency_by_url = payload.currency_by_url.toLowerCase()

            if (currency_by_url == "brl") {
                defaultUserMonetary = monetaries.bra
            } else {
                defaultUserMonetary = monetaries.usa
            }

            commit('MUTATE_USER_MONETARY', defaultUserMonetary)

        } else {
            commit('MUTATE_USER_MONETARY', payload)
        }

        console.log(">> User monetary fetched")
    },

    async setPartnerByPreCustomer({ commit, state }, partner) {
        commit('MUTATE_PARTNER', partner)
        console.log(">> Partner defined by pre-customer (setPartner)!", partner)
        return partner
    },

    async updateShoppingCart({ commit, state }, payload) {
        state.shopping_cart[payload.key] = payload.value
        return state.shopping_cart
    },

    async clearShoppingCart({ commit, state }, payload) {
        state.shopping_cart = {
            selected_plan: null,
            selected_addons: null,
            payment: null,
            account: null
        }
        return state.shopping_cart
    },


    async getPosts({ commit, state }, payload) {

        const url = state.api.baseUrl + state.api.posts.link

        return await $.ajax({
            url: url,
            type: "get",
            dataType: "json",
            data: {},
            beforeSend: function (xhr) {
                console.log(">> Getting posts data (getPosts)...")
                xhr.setRequestHeader('Content-Type', 'application/json')
                xhr.setRequestHeader('Authorization', 'Bearer ' + state.api.token.access_token)
            },
            success: function (response, textStatus, jqXHR) {
                if (jqXHR.status === 200) {
                    const posts_list = response.data
                    commit('MUTATE_POSTS_LIST', posts_list)
                    console.log(">> Got posts data (getPosts)!")
                    return posts_list
                } else {
                    return {}
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                console.error(">> Error (getPosts): ", jqXHR, textStatus, errorThrown)
                return {}
            }
        })

    },


    async getPostsCategories({ commit, state }, payload) {

        const url = state.api.baseUrl + state.api.posts.getPostsCategories

        return await $.ajax({
            url: url,
            type: "get",
            dataType: "json",
            data: {},
            beforeSend: function (xhr) {
                console.log(">> Getting posts categories data (getPostsCategories)...")
                xhr.setRequestHeader('Content-Type', 'application/json')
                xhr.setRequestHeader('Authorization', 'Bearer ' + state.api.token.access_token)
            },
            success: function (response, textStatus, jqXHR) {
                if (jqXHR.status === 200) {
                    let posts_categories = response

                    for (var i in posts_categories) {
                        posts_categories[i].title = JSON.parse(posts_categories[i].title)
                    }

                    commit('MUTATE_POSTS_CATEGORIES', posts_categories)
                    console.log(">> Got posts categories data (getPostsCategories)!")
                    return posts_categories
                } else {
                    return {}
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                console.error(">> Error (getPostsCategories): ", jqXHR, textStatus, errorThrown)
                return {}
            }
        })

    },


    async getPostsAuthors({ commit, state }, payload) {

        const url = state.api.baseUrl + state.api.posts.getPostsAuthors

        return await $.ajax({
            url: url,
            type: "get",
            dataType: "json",
            data: {},
            beforeSend: function (xhr) {
                console.log(">> Getting posts authors data (getPostsAuthors)...")
                xhr.setRequestHeader('Content-Type', 'application/json')
                xhr.setRequestHeader('Authorization', 'Bearer ' + state.api.token.access_token)
            },
            success: function (response, textStatus, jqXHR) {
                if (jqXHR.status === 200) {
                    const posts_authors = response
                    commit('MUTATE_POSTS_AUTHORS', posts_authors)
                    console.log(">> Got posts authors data (getPostsAuthors)!")
                    return posts_authors
                } else {
                    return {}
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                console.error(">> Error (getPostsAuthors): ", jqXHR, textStatus, errorThrown)
                return {}
            }
        })

    },


    async getPost({ commit, state }, payload) {

        const url = state.api.baseUrl + state.api.posts.getPost.replace("{post}", payload.id)

        return await $.ajax({
            url: url,
            type: "get",
            dataType: "json",
            beforeSend: function (xhr) {
                console.log(">> Getting post data (getPost)...")
                xhr.setRequestHeader('Content-Type', 'application/json')
                xhr.setRequestHeader('Authorization', 'Bearer ' + state.api.token.access_token)
            },
            success: function (response, textStatus, jqXHR) {
                if (jqXHR.status === 200) {
                    let post = response.data[0]

                    commit('MUTATE_POST', post)
                    console.log(">> Got post data (getPost)!")
                    return post
                } else {
                    return {}
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                console.error(">> Error (getPost): ", jqXHR, textStatus, errorThrown)
                return {}
            }
        })

    },

    async getPartner({ commit, state }, payload) {
        const url = state.api.baseUrl + state.api.partners.getPartnerBySlug
        const domain_location = window.location.hostname.replace("https://", "").replace("http://", "").replace("www.", "")

        return await $.ajax({
            url: url,
            type: "get",
            dataType: "json",
            data: {
                id: payload.id,
                slug: payload.slug,
                domain_location: domain_location
            },
            beforeSend: function (xhr) {
                console.log(">> Getting partner data (getPartner)...")
                xhr.setRequestHeader('Content-Type', 'application/json')
                xhr.setRequestHeader('Authorization', 'Bearer ' + state.api.token.access_token)
            },
            success: function (response, textStatus, jqXHR) {
                if (jqXHR.status === 200) {
                    var partner = response

                    if (!partner.active) {
                        partner = {}
                        console.log("Inactive partner")
                    } else {
                        console.log(">> Got partner data (getPartner)!", partner)
                    }

                    commit('MUTATE_PARTNER', partner)
                    return partner

                } else {
                    return {}
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                console.error(">> Error (getPartner): ", jqXHR, textStatus, errorThrown)
                return {}
            }
        })
    },

    async setUserMonetaryByLocation({ commit, state }) {

        console.log(">> setUserMonetaryByLocation")

        const domain_languages = state.domain.languages

        await axios.get('/api/getUserIp').then(response => {
            const userData = response.data
            commit('MUTATE_USER_IP', userData.user_ip)
        })

        if (domain_languages.length == 1 && domain_languages[0] == 'pt_br' && state.domain.name != 'deketafy') {

            defaultUserMonetary = monetaries.bra
            commit('MUTATE_USER_MONETARY', defaultUserMonetary)
            console.info(">> Default user monetary set by domain unique language", domain_languages[0])

        } else {

            console.log(">> Getting user location...")
            console.log(">> Getting data from geolocate......")

            await axios.get('/api/geolocate').then(response => {
                console.log(" >> API GEOLOCATE RETURN", response.data)

                if (response.data.ip == '::1') {
                    console.log(" >> Undefined IP")
                    commit('MUTATE_USER_MONETARY', defaultUserMonetary)
                    console.info(">> Default user monetary set by default due to undefined IP")
                    return
                }

                const geoData = response.data,
                    geoCurrency = geoData.currency.code.toLowerCase(),
                    ioc = geoData.country_code.toLowerCase(),
                    acceptedCurrencies = ["usd", "brl"]

                var user_monetary = null,
                    msg = null

                if (acceptedCurrencies.includes(geoCurrency)) {

                    user_monetary = {
                        ioc: ioc,
                        symbol: (geoCurrency == 'brl') ? "R$" : "$",
                        currency_code: geoCurrency,
                        separators: {
                            cents: (geoCurrency == 'brl') ? "," : ".",
                            other: (geoCurrency == 'brl') ? "." : ",",
                        }
                    }

                    msg = ">> Default user monetary set by location"

                } else {

                    user_monetary = monetaries.usa
                    msg = ">> Default user monetary set by default because the geo currency is not supported: "

                }

                console.info(msg, geoCurrency, user_monetary)

                commit('MUTATE_USER_MONETARY', user_monetary)

            }).catch(err => {

                console.log(" >> API GEOLOCATE ERROR", err)
                commit('MUTATE_USER_MONETARY', defaultUserMonetary)
                console.info(">> Default user monetary set by default")
            })
        }

    },

    async setEnv({ commit }, payload) {
        commit('MUTATE_ENV', payload)
    },

    async setLocale({ commit }, payload) {
        console.log(">> Setting new locale", payload)
        commit('MUTATE_LOCALE', payload)
    },

    async setDomain({ commit, state }) {
        console.log(">> Setting domain...")

        const domain_location = window.location.hostname.replace("https://", "").replace("http://", "").replace("www.", "")

        console.log(">> Domain location", domain_location)

        await axios.get('/api/domain', {
            params: {
                location: domain_location,
                token: state.api.token.access_token
            }
        }).then(response => {

            const data = response.data.data ? response.data.data : response.data
            let domain = (Object.keys(data).length > 0) ? data : defaultDomain

            console.log(">> Domain by location", domain)
            commit('MUTATE_DOMAIN', domain)

        }).catch(err => {
            console.info(" >> Default domain set")
            commit('MUTATE_DOMAIN', defaultDomain)
        })
    },

    async setApiToken({ commit, state }) {
        console.log(">> Setting api token...")
        commit('MUTATE_API_TOKEN', null)

        await axios.get('/api/token').then(response => {
            const token = response.data
            commit('MUTATE_API_TOKEN', token)
        }).catch(err => {
            console.error(" >> Error in app.vue -> setApiToken", err)
        })
    },

    async getSellablePlans({ commit, state }, payload = null) {
        const url = state.api.baseUrl + state.api.plans.getSellablePlans

        await $.ajax({
            url: url,
            type: "get",
            dataType: "json",
            data: payload,
            beforeSend: function (xhr) {
                console.log(">> Getting the plans (getSellablePlans)...")
                xhr.setRequestHeader('Content-Type', 'application/json')
                xhr.setRequestHeader('Authorization', 'Bearer ' + state.api.token.access_token)
            },
            success: function (response, textStatus, jqXHR) {
                if (jqXHR.status === 200) {
                    const plans = response
                    commit('MUTATE_PLANS', plans)
                    console.log(">> Got the plans (getSellablePlans)!")
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                console.error(">> Error (getSellablePlans): ", jqXHR, textStatus, errorThrown)
            }
        })

    },


    async getPlan({ commit, state }, plan_id) {
        const url = state.api.baseUrl + state.api.plans.link + `/${plan_id}`

        return await $.ajax({
            url: url,
            type: "get",
            dataType: "json",
            beforeSend: function (xhr) {
                console.log(">> Getting plan by id (getPlan)...")
                xhr.setRequestHeader('Content-Type', 'application/json')
                xhr.setRequestHeader('Authorization', 'Bearer ' + state.api.token.access_token)
            },
            success: function (response, textStatus, jqXHR) {
                console.log(">> Got plan by id (getPlan)!")
                return response
            },
            error: function (jqXHR, textStatus, errorThrown) {
                console.error(">> Error (getPlan): ", jqXHR, textStatus, errorThrown)
                return {}
            }
        })

    },


    async setPreCustomer({ commit }, payload) {
        console.log(">> Pre-customer defined by checkout page (setPreCustomer)!", payload)
        commit('MUTATE_PRE_CUSTOMER', payload)
    },

    async savePreCustomer({ commit, state }, payload = null) {

        const url = state.api.baseUrl + state.api.customers.savePreCustomer

        return await $.ajax({
            url: url,
            type: "post",
            dataType: "json",
            data: payload,
            beforeSend: function (xhr) {
                console.log(">> Saving new pre-customer (savePreCustomer)...")
                xhr.setRequestHeader('Authorization', 'Bearer ' + state.api.token.access_token)
            },
            success: function (response, textStatus, jqXHR) {

                if (jqXHR.status === 200) {
                    const customer = response
                    commit('MUTATE_PRE_CUSTOMER', customer)
                    console.log(">> Pre-customer saved! (savePreCustomer)!")
                    return customer
                } else {
                    return []
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                console.error(">> Error (savePreCustomer): ", jqXHR, textStatus, errorThrown)
                return textStatus
            }
        })


    },


    async savePrePartner({ commit, state }, payload = null) {

        const url = state.api.baseUrl + state.api.partners.savePrePartner

        return await $.ajax({
            url: url,
            type: "post",
            dataType: "json",
            data: payload,
            beforeSend: function (xhr) {
                console.log(">> Saving new pre-partner (savePrePartner)...")
                xhr.setRequestHeader('Authorization', 'Bearer ' + state.api.token.access_token)
            },
            success: function (response, textStatus, jqXHR) {

                if (jqXHR.status === 200) {
                    const partner = response
                    console.log(">> Pre-partner saved! (savePrePartner)!")
                    return partner
                } else {
                    return []
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                console.error(">> Error (savePrePartner): ", jqXHR, textStatus, errorThrown)
                return textStatus
            }
        })


    },


    async editPreCustomer({ commit, state }, payload = null) {

        const url = state.api.baseUrl + state.api.customers.editPreCustomer.replace("{pre_customer}", payload.id)

        return await $.ajax({
            url: url,
            type: "put",
            dataType: "json",
            data: payload.data,
            beforeSend: function (xhr) {
                console.log(">> Updating existing pre-customer (editPreCustomer)...")
                xhr.setRequestHeader('Authorization', 'Bearer ' + state.api.token.access_token)
            },
            success: function (response, textStatus, jqXHR) {

                if (jqXHR.status === 200) {
                    const customer = response
                    commit('MUTATE_PRE_CUSTOMER', customer)
                    console.log(">> Pre-customer updated! (editPreCustomer)!")
                    return customer
                } else {
                    return []
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                console.error(">> Error (savePreCustomer): ", jqXHR, textStatus, errorThrown)
                return textStatus
            }
        })


    },


    async editPrePartner({ commit, state }, payload = null) {

        const url = state.api.baseUrl + state.api.partners.editPrePartner.replace("{pre_partner}", payload.id)

        return await $.ajax({
            url: url,
            type: "put",
            dataType: "json",
            data: payload.data,
            beforeSend: function (xhr) {
                console.log(">> Updating existing pre-partner (editPrePartner)...")
                xhr.setRequestHeader('Authorization', 'Bearer ' + state.api.token.access_token)
            },
            success: function (response, textStatus, jqXHR) {

                if (jqXHR.status === 200) {
                    const partner = response
                    console.log(">> Pre-partner updated! (editPrePartner)!")
                    return partner
                } else {
                    return []
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                console.error(">> Error (savePrePartner): ", jqXHR, textStatus, errorThrown)
                return textStatus
            }
        })


    },

    async saveCustomer({ commit, state }, payload = null) {

        const url = state.api.baseUrl + state.api.customers.link

        return await $.ajax({
            url: url,
            type: "post",
            dataType: "json",
            data: payload,
            beforeSend: function (xhr) {
                console.log(">> Saving new customer (saveCustomer)...")
                xhr.setRequestHeader('Authorization', 'Bearer ' + state.api.token.access_token)
            },
            success: function (response, textStatus, jqXHR) {
                if (jqXHR.status === 200) {
                    const customer = response
                    commit('MUTATE_CUSTOMER', customer)
                    console.log(">> Customer saved! (saveCustomer)!")
                    return customer
                } else {
                    return []
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                console.error(">> Error (saveCustomer): ", jqXHR, textStatus, errorThrown)
                return textStatus
            }
        })


    },


    async saveWorkspace({ commit, state }, payload = null) {
        const url = state.api.baseUrl + state.api.workspaces.link

        return await $.ajax({
            url: url,
            type: "post",
            dataType: "json",
            data: payload,
            beforeSend: function (xhr) {
                console.log(">> Saving new workspace (saveWorkspace)...")
                xhr.setRequestHeader('Authorization', 'Bearer ' + state.api.token.access_token)
            },
            success: function (response, textStatus, jqXHR) {
                if (jqXHR.status === 200) {
                    const workspace = response
                    commit('MUTATE_WORKSPACE', workspace)
                    console.log(">> Workspace saved! (saveWorkspace)!")
                    return workspace

                } else {
                    return []
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                console.error(">> Error (saveWorkspace): ", jqXHR, textStatus, errorThrown)
                return textStatus
            }
        })


    },


    async setEmailConfirmed({ commit, state }, payload = null) {

        const url = state.api.baseUrl + state.api.customers.setEmailConfirmed.replace("{pre_customer}", payload.id)

        return await $.ajax({
            url: url,
            type: "put",
            dataType: "json",
            data: {
                email: payload.email,
            },
            beforeSend: function (xhr) {
                console.log(">> Setting email confirmed (setEmailConfirmed)...")
                xhr.setRequestHeader('Content-Type', 'application/json')
                xhr.setRequestHeader('Authorization', 'Bearer ' + state.api.token.access_token)
            },
            success: function (response, textStatus, jqXHR) {

                if (jqXHR.status === 200) {
                    state.pre_customer.email_confirmed = true
                    console.log(">> Email confirmed! (setEmailConfirmed)!", response.data)
                    return true
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                console.error(">> Error (setEmailConfirmed): ", jqXHR, textStatus, errorThrown)
                return false
            }
        })

    },

    async getPreCustomer({ commit, state }, payload = null) {

        const url = state.api.baseUrl + state.api.customers.getPreCustomer.replace("{pre_customer}", payload.id).replace("{email}", payload.email)

        return await $.ajax({
            url: url,
            type: "get",
            dataType: "json",
            beforeSend: function (xhr) {
                console.log(">> Getting customer by id (getPreCustomer)...")
                xhr.setRequestHeader('Content-Type', 'application/json')
                xhr.setRequestHeader('Authorization', 'Bearer ' + state.api.token.access_token)
            },
            success: function (response, textStatus, jqXHR) {

                if (jqXHR.status === 200 && Object.keys(response).length > 0) {
                    const customer = response
                    commit('MUTATE_PRE_CUSTOMER', customer)
                    console.log(">> Got customer (getPreCustomer)!", customer)
                    return customer
                } else {
                    return []
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                console.error(">> Error (getPreCustomer): ", jqXHR, textStatus, errorThrown)
                return textStatus
            }
        })


    },

    async getPreCustomerByEmail({ commit, state }, payload = null) {

        const url = state.api.baseUrl + state.api.customers.getPreCustomerByEmail

        return await $.ajax({
            url: url,
            type: "get",
            dataType: "json",
            data: {
                email: payload.email,
            },
            beforeSend: function (xhr) {
                console.log(">> Getting customer by email (getPreCustomerByEmail)...")
                xhr.setRequestHeader('Content-Type', 'application/json')
                xhr.setRequestHeader('Authorization', 'Bearer ' + state.api.token.access_token)
            },
            success: function (response, textStatus, jqXHR) {

                if (jqXHR.status === 200 && response.length > 0) {
                    const customer = response
                    commit('MUTATE_PRE_CUSTOMER', customer)
                    console.log(">> Got customer (getPreCustomerByEmail)!", customer)
                    return customer
                } else {
                    return []
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                console.error(">> Error (getPreCustomerByEmail): ", jqXHR, textStatus, errorThrown)
                return textStatus
            }
        })


    },

    async getPrePartnerByEmail({ commit, state }, payload = null) {

        const url = state.api.baseUrl + state.api.partners.getPrePartnerByEmail

        return await $.ajax({
            url: url,
            type: "get",
            dataType: "json",
            data: {
                email: payload.email,
            },
            beforeSend: function (xhr) {
                console.log(">> Getting partner by email (getPrePartnerByEmail)...")
                xhr.setRequestHeader('Content-Type', 'application/json')
                xhr.setRequestHeader('Authorization', 'Bearer ' + state.api.token.access_token)
            },
            success: function (response, textStatus, jqXHR) {

                if (jqXHR.status === 200 && response.length > 0) {
                    const partner = response
                    console.log(">> Got partner (getPrePartnerByEmail)!", partner)
                    return partner
                } else {
                    return []
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                console.error(">> Error (getPrePartnerByEmail): ", jqXHR, textStatus, errorThrown)
                return textStatus
            }
        })


    },

    async getAddons({ commit, state }) {
        const url = state.api.baseUrl + state.api.plans.getAddons

        await $.ajax({
            url: url,
            type: "get",
            dataType: "json",
            beforeSend: function (xhr) {
                console.log(">> Getting all the addons (getAddons)...")
                xhr.setRequestHeader('Content-Type', 'application/json')
                xhr.setRequestHeader('Authorization', 'Bearer ' + state.api.token.access_token)
            },
            success: function (response, textStatus, jqXHR) {
                if (jqXHR.status === 200) {
                    const addons = response.data
                    commit('MUTATE_ADDONS', addons)
                    console.log(">> Got the addons (getAddons)!")
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                console.error(">> Error (getAddons): ", jqXHR, textStatus, errorThrown)
            }
        })
    },


    async getAddonByKey({ commit, state }, payload) {

        const url = state.api.baseUrl + state.api.plans.getAddonByKey

        await $.ajax({
            url: url,
            type: "get",
            dataType: "json",
            data: {
                key: payload.key
            },
            beforeSend: function (xhr) {
                console.log(">> Getting addon by key (getAddonByKey)...")
                xhr.setRequestHeader('Content-Type', 'application/json')
                xhr.setRequestHeader('Authorization', 'Bearer ' + state.api.token.access_token)
            },
            success: function (response, textStatus, jqXHR) {

                if (jqXHR.status === 200) {

                    const addons = response.data
                    commit('MUTATE_ADDONS', addons)
                    console.log(">> Got addon by key (getAddonByKey)!")

                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                console.error(">> Error (getAddonByKey): ", jqXHR, textStatus, errorThrown)
            }
        })
    },

    async sendJobRequest({ commit, state }, payload) {

        payload.domain = state.domain

        const data = JSON.stringify(payload)

        const url = state.api.baseUrl + state.api.notification.careers

        return await $.ajax({
            url: url,
            type: "POST",
            dataType: "json",
            data: data,
            async: true,
            beforeSend: function (xhr) {
                console.log(">> Sending job request (sendJobRequest)...")
                xhr.setRequestHeader('Content-Type', 'application/json')
                xhr.setRequestHeader('Authorization', 'Bearer ' + state.api.token.access_token)
            },
            success: function (response, textStatus, jqXHR) {

                console.log(">> Job request sent...", response)
                return response
            },
            error: function (jqXHR, textStatus, errorThrown) {
                console.error(">> Error (sendJobRequest): ", jqXHR, textStatus, errorThrown)
            }

        }).always(function () {

        })

    },


    async sendPartnershipRequest({ commit, state }, payload) {
        payload.domain = state.domain

        console.log(">> Sending partnership request (sendPartnershipRequest)...", payload)

        const data = JSON.stringify(payload)

        const url = state.api.baseUrl + state.api.notification.partnership

        return await $.ajax({
            url: url,
            type: "POST",
            dataType: "json",
            data: data,
            async: true,
            beforeSend: function (xhr) {
                console.log(">> Sending job request (sendPartnershipRequest)...")
                xhr.setRequestHeader('Content-Type', 'application/json')
                xhr.setRequestHeader('Authorization', 'Bearer ' + state.api.token.access_token)
            },
            success: function (response, textStatus, jqXHR) {
                console.log(">> Partnership request sent...", response)
                return response
            },
            error: function (jqXHR, textStatus, errorThrown) {
                console.error(">> Error (sendPartnershipRequest): ", jqXHR, textStatus, errorThrown)
            }
        })
    },


    async sendInterfyEmail({ commit, state }, payload) {
        payload.domain = state.domain

        const data = JSON.stringify(payload)

        const url = state.api.baseUrl + state.api.notification.workspace_created

        return await $.ajax({
            url: url,
            type: "POST",
            dataType: "json",
            data: data,
            async: true,
            beforeSend: function (xhr) {
                console.log(">> Sending job request (sendInterfyEmail)...")
                xhr.setRequestHeader('Content-Type', 'application/json')
                xhr.setRequestHeader('Authorization', 'Bearer ' + state.api.token.access_token)
            },
            success: function (response, textStatus, jqXHR) {

                console.log(">> Workspace created notification sent...", response)
                return response
            },
            error: function (jqXHR, textStatus, errorThrown) {
                console.error(">> Error (sendInterfyEmail): ", jqXHR, textStatus, errorThrown)
            }

        }).always(function () {

        })

    },


    async sendEmailConfirmation({ commit, state }, payload) {

        payload.domain = state.domain

        const data = JSON.stringify(payload)

        const url = state.api.baseUrl + state.api.notification.emailConfirmation

        return await $.ajax({
            url: url,
            type: "POST",
            dataType: "json",
            data: data,
            async: true,
            beforeSend: function (xhr) {
                console.log(">> Sending email confirmation (sendEmailConfirmation)...")
                xhr.setRequestHeader('Content-Type', 'application/json')
                xhr.setRequestHeader('Authorization', 'Bearer ' + state.api.token.access_token)
            },
            success: function (response, textStatus, jqXHR) {

                console.log(">> Email confirmation sent...", response)
                return response
            },
            error: function (jqXHR, textStatus, errorThrown) {
                console.error(">> Error (sendEmailConfirmation): ", jqXHR, textStatus, errorThrown)
                return textStatus
            }

        }).always(function () {

        })

    },


    async sendNewsletter({ commit, state }, payload) {

        payload.domain = state.domain

        const data = JSON.stringify(payload)

        const url = state.api.baseUrl + state.api.notification.newsletter

        return await $.ajax({
            url: url,
            type: "POST",
            dataType: "json",
            data: data,
            async: true,
            beforeSend: function (xhr) {
                console.log(">> Sending newsletter request (sendNewsletter)...")
                xhr.setRequestHeader('Content-Type', 'application/json')
                xhr.setRequestHeader('Authorization', 'Bearer ' + state.api.token.access_token)
            },
            success: function (response, textStatus, jqXHR) {

                console.log(">> Newsletter request sent...", response)
                return response
            },
            error: function (jqXHR, textStatus, errorThrown) {
                console.error(">> Error (sendNewsletter): ", jqXHR, textStatus, errorThrown)
            }

        }).always(function () {

        })

    },

    async upload({ commit, state }, payload) {

        const url = state.api.baseUrl + state.api.upload

        return await $.ajax({
            url: url,
            type: "POST",
            enctype: 'multipart/form-data',
            data: payload,
            processData: false,
            contentType: false,
            cache: false,
            timeout: 600000,
            async: true,
            beforeSend: function (xhr) {
                console.log(">> Uploading (upload)...")
                xhr.setRequestHeader('Authorization', 'Bearer ' + state.api.token.access_token)
            },
            success: function (response, textStatus, jqXHR) {
                console.log(">> Uploaded!")
                return response
            },
            error: function (jqXHR, textStatus, errorThrown) {
                console.error(">> Error (upload): ", jqXHR, textStatus, errorThrown)
            }
        }).always(function () {

        })
    },


    async getCepData({ commit, state }, payload = null) {

        const url = state.api.baseUrl + state.api.misc.getCepData

        return await $.ajax({
            url: url,
            type: "get",
            dataType: "json",
            data: {
                cep: payload
            },
            beforeSend: function (xhr) {
                console.log(">> Getting the cep data (getCepData)...")
                xhr.setRequestHeader('Content-Type', 'application/json')
                xhr.setRequestHeader('Authorization', 'Bearer ' + state.api.token.access_token)
            },
            success: function (response, textStatus, jqXHR) {
                return response
            },
            error: function (jqXHR, textStatus, errorThrown) {
                console.error(">> Error (getCepData): ", jqXHR, textStatus, errorThrown)
                return {}
            }
        })

    }
}

const mutations = {
    MUTATE_PARTNER: (state, partner) => (state.partner = partner),
    MUTATE_LOCALE: (state, locale) => (state.locale = locale),
    MUTATE_USER_MONETARY: (state, user_monetary) => (state.user_monetary = user_monetary),
    MUTATE_USER_IP: (state, user_ip) => (state.user_ip = user_ip),
    MUTATE_ENV: (state, env) => (state.env = env),
    MUTATE_API_TOKEN: (state, apiToken) => (state.api.token = apiToken),
    MUTATE_DOMAIN: (state, domain) => {
        state.domain = domain
    },
    MUTATE_PLANS: (state, plans) => (state.plans = plans),
    MUTATE_PRE_CUSTOMER: (state, pre_customer) => (state.pre_customer = pre_customer),
    MUTATE_CUSTOMER: (state, customer) => (state.customer = customer),
    MUTATE_WORKSPACE: (state, workspace) => (state.workspace = workspace),
    MUTATE_ADDONS: (state, addons) => (state.addons = addons),
    MUTATE_POSTS_LIST: (state, posts_list) => (state.posts_list = posts_list),
    MUTATE_POSTS_AUTHORS: (state, posts_authors) => (state.posts_authors = posts_authors),
    MUTATE_POSTS_CATEGORIES: (state, posts_categories) => (state.posts_categories = posts_categories),
    MUTATE_POST: (state, post) => (state.post = post)
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}