import { render, staticRenderFns } from "./FAQ.vue?vue&type=template&id=13277704&scoped=true"
import script from "./FAQ.vue?vue&type=script&lang=js"
export * from "./FAQ.vue?vue&type=script&lang=js"
import style0 from "./FAQ.vue?vue&type=style&index=0&id=13277704&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13277704",
  null
  
)

export default component.exports