<template>
    <div>
        <b-navbar toggleable="lg" class="top-menu">
            <b-navbar-brand>
                <router-link class="no-border" :to="`/home${partner_url}`">
                    <img class="d-inline-block align-top logo-top"
                        :src="`/assets/images/domains/${domain.name}/logo_01.png`" :alt="`${domain.title}`">
                    <!-- {{(user_monetary) ? user_monetary.ioc : ''}} -->
                </router-link>
            </b-navbar-brand>

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav class="ml-auto links-center">

                    <li class="nav-item first-level"><router-link class="nav-link" :to="`/plans${partner_url}`">{{
                        $t('PRICES') }} </router-link></li>

                    <b-nav-item-dropdown :text="$t('PRODUCTS')" left class="first-level">
                        <span class='triangle'></span>
                        <b-dropdown-item><router-link class="nav-link d-flex flex-row align-items-center"
                                :to="`/ecm${partner_url}`"><i class="icon-ecm logo-ecm mr-2"
                                    :alt="$t('ECM_NO_TAGS')"></i> <span
                                    v-html="`ECM - ${$t('ECM_NO_TAGS')}`"></span></router-link></b-dropdown-item>
                        <b-dropdown-item><router-link class="nav-link d-flex flex-row align-items-center"
                                :to="`/bpm${partner_url}`"><i class="icon-bpm logo-bpm mr-2"
                                    :alt="$t('BPM_NO_TAGS')"></i> <span
                                    v-html="`BPM - ${$t('BPM_NO_TAGS')}`"></span></router-link></b-dropdown-item>

                        <b-dropdown-item><router-link class="nav-link d-flex flex-row align-items-center"
                                :to="`/swc${partner_url}`"><i class="icon-swc logo-swc mr-2"
                                    :alt="$t('SWC_NO_TAGS')"></i> <span
                                    v-html="`SWC - ${$t('SWC_NO_TAGS')}`"></span></router-link></b-dropdown-item>
                        <b-dropdown-item><router-link class="nav-link d-flex flex-row align-items-center"
                                :to="`/dss${partner_url}`"><i class="icon-dss logo-dss mr-2"
                                    :alt="$t('DSS_NO_TAGS')"></i> <span
                                    v-html="`DSS - ${$t('DSS_NO_TAGS')}`"></span></router-link></b-dropdown-item>


                        <!-- v-if="env == 'localhost' || (user_monetary && user_monetary.ioc == 'br')" -->
                        <b-dropdown-item><router-link class="nav-link d-flex flex-row align-items-center"
                                :to="`/slv${partner_url}`"><i class="icon-slv logo-slv mr-2"
                                    :alt="$t('SLV_NO_TAGS')"></i> <span
                                    v-html="`LVS - ${$t('SLV_NO_TAGS')}`"></span></router-link></b-dropdown-item>
                        <b-dropdown-item v-if="this.$i18n.locale == 'en'"><router-link
                                class="nav-link d-flex flex-row align-items-center" :to="`/cic${partner_url}`"><i
                                    class="far fa-comments mr-2 font-cic" :alt="$t('CIC_NO_TAGS')"></i> <span
                                    v-html="`CIC - ${$t('CIC_NO_TAGS')}`"></span></router-link></b-dropdown-item>
                        <b-dropdown-item
                            v-if="this.$i18n.locale == 'pt_br' && (env == 'localhost' || (user_monetary && user_monetary.ioc == 'br'))"><router-link
                                class="nav-link d-flex flex-row align-items-center" :to="`/das${partner_url}`"><i
                                    class="icon-das logo-das mr-2" :alt="$t('DAS_NO_TAGS')"></i> <span
                                    v-html="`DAS - ${$t('DAS_NO_TAGS')}`"></span></router-link></b-dropdown-item>

                    </b-nav-item-dropdown>
                    <b-nav-item-dropdown :text="$t('PROCESSES')" left class="first-level">
                        <span class='triangle'></span>
                        <b-dropdown-item><router-link class="nav-link" :to="`/process/customerService${partner_url}`"
                                v-html="$t('CUSTOMER SERVI')"></router-link></b-dropdown-item>
                        <b-dropdown-item><router-link class="nav-link" :to="`/process/purchaseOrder${partner_url}`"
                                v-html="$t('PURCHASE ORDER')"></router-link></b-dropdown-item>
                        <b-dropdown-item><router-link class="nav-link" :to="`/process/contractManagement${partner_url}`"
                                v-html="$t('CONTRACT MANAG')"></router-link></b-dropdown-item>
                        <b-dropdown-item><router-link class="nav-link"
                                :to="`/process/paymentAuthorization${partner_url}`"
                                v-html="$t('PAYMENT AUTHOR')"></router-link></b-dropdown-item>
                        <b-dropdown-item><router-link class="nav-link" :to="`/process/expensesRefund${partner_url}`"
                                v-html="$t('EXPENSES REFUN')"></router-link></b-dropdown-item>
                        <b-dropdown-item><router-link class="nav-link"
                                :to="`/process/recruitmentAndSelection${partner_url}`"
                                v-html="$t('RECRUITMENT AN')"></router-link></b-dropdown-item>

                    </b-nav-item-dropdown>
                    <li class="nav-item first-level"><router-link class="nav-link" :to="`/solutions${partner_url}`">{{
                        $t('SOLUTIONS') }}</router-link></li>
                    <li v-if="domain.type == 'interfy'" class="nav-item first-level"><router-link class="nav-link"
                            :to="`/blog${partner_url}`">{{ $t('BLOG') }}</router-link></li>
                    <li class="nav-item" :class="{
                        'btn btn-success btn-success-alternative': window.width > 991,
                        'first-level': window.width <= 991,
                    }">
                        <router-link class="nav-link" :to="`/start-free${partner_url}`">{{ $t('START_FREE')
                            }}</router-link>
                        <!-- <router-link class="nav-link" :to="`/plans${partner_url}`">{{ $t('START_FREE') }}</router-link> -->
                    </li>

                    <li v-if="isDomainPermitted && (domain.type == 'interfy' || domain.type == 'distribuitor')"
                        class="nav-item" :class="{
                            'btn btn-blue btn-blue-alternative': window.width > 991,
                            'first-level': window.width <= 991,
                        }">
                        <router-link class="nav-link" :to="`/become-a-partner${partner_url}`">{{ $t('BECOME_A_PARTNER')
                            }}</router-link>
                    </li>


                    <li v-if="partner && partner.phone1 && domain.type == 'interfy'" class="nav-item" :class="{
                        'btn btn-blue btn-blue-alternative': window.width > 991,
                        'first-level': window.width <= 991,
                    }">
                        <div class="d-flex">
                            <a :href="'tel:' + partner.phone1" class="nav-link">
                                <i class="fa fa-phone mr-2" /> <span>{{ partner.phone1 }}</span>
                            </a>
                        </div>
                    </li>
                </b-navbar-nav>

                <!-- 
                    Shopping cart
                -->
                <!-- <b-navbar-nav v-if="hasPreCustomer()">
                    <i @click.stop="gotoCheckout()" class="fas fa-shopping-cart d-flex flex-column justify-content-center align-items-center mr-3"></i>
                </b-navbar-nav> -->

                <!-- 
                    Language
                -->

                <b-navbar-nav v-if="domain.name == 'localhost' || domain.languages.length > 1"
                    class="ml-auto language-menu">
                    <i class="fas fa-map-marked-alt d-flex flex-column justify-content-center align-items-center"></i>
                    <b-nav-item-dropdown :text="`${$t($i18n.locale)}`" right class="first-level">

                        <b-dropdown-item v-for="(item, index) in languages" :key="index"
                            @click="changeLocale(item.lang)">
                            <img :src="`/assets/images/flags/${item.image}`" :alt="item.title"> {{ item.title }}
                        </b-dropdown-item>

                    </b-nav-item-dropdown>
                </b-navbar-nav>

            </b-collapse>
        </b-navbar>
    </div>
</template>

<script>
import $ from 'jquery'
// import PlansMixin from '../../mixins/PlansMixin'
import { mapState, mapActions } from 'vuex'
import { BNavbar } from 'bootstrap-vue'
export default {
    // mixins: [PlansMixin],
    components: {
        BNavbar
    },
    name: 'TopMenu',
    props: {
        isDomainPermitted: {
            type: Boolean,
            default: false
        },
        partner_url: {
            type: String,
            default: ''
        },
        window: {
            type: Object,
            default() {
                return {
                    width: 0,
                    height: 0
                }
            }
        },
    },
    computed: {
        ...mapState('MainStoreModule', {
            language: state => state.language,
            domain: state => state.domain,
            user_monetary: state => state.user_monetary,
            env: state => state.env,
            partner: (state) => state.partner
        }),

        languages() {
            var languages = []

            let domain_languages = this.domain.languages
            if (typeof domain_languages == 'string') {
                domain_languages = JSON.parse(domain_languages)
            }

            console.log('domain_languages', domain_languages)
            for (var i in domain_languages) {
                let lang = domain_languages[i],
                    title = null,
                    image = null

                if (lang == 'pt_br') {
                    title = 'Português'
                    image = "brazil.png"

                } else if (lang == 'es') {
                    title = 'Español'
                    image = "spain.png"

                } else {
                    title = 'English'
                    image = "united-states-of-america.png"
                }

                languages.push({
                    'lang': lang,
                    'title': title,
                    'image': image
                })
            }

            return languages

        },
    },

    mounted() {

        $('.links-center .b-nav-dropdown').mouseover(function (e) {
            $(this).addClass("show")
            $(this).find(".nav-link.dropdown-toggle").attr("aria-expanded", "true")
            $(this).find("ul").addClass("show")
        });
        $('.links-center .b-nav-dropdown').mouseleave(function (e) {
            $(this).removeClass("show")
            $(this).find(".nav-link.dropdown-toggle").attr("aria-expanded", "false")
            $(this).find("ul").removeClass("show")
        });
    },
    methods: {
        ...mapActions('MainStoreModule', [
            'setLocale'
        ]),

        changeLocale(locale) {
            this.$i18n.locale = locale
            this.setLocale(locale)
            this.$moment.locale(locale)
        },

        gotoCheckout() {
            this.selectedPlan = {
                "plan_id": this.storage_pre_customer.plan_id,
                "method": this.storage_pre_customer.method,
                "isTrial": this.checkIsTrial(this.storage_pre_customer.isTrial)
            }
            this.redirectToCheckout(false)
        },

        checkIsTrial(isTrial) {
            if (isTrial === true || isTrial === 'true' || isTrial === 1) {
                return true
            }

            if (isTrial === false || isTrial === 'false' || isTrial === 0) {
                return false
            }
        }
    },
}
</script>

<style lang="scss">
.fa-shopping-cart {
    cursor: pointer;
    color: #4669F8;
    transition: all .4s ease;

    &:hover {
        color: #EE8600;
    }
}


.top-menu {
    font-size: 16px !important;
}

.top-menu a {
    outline: none !important;
    color: rgba(28, 112, 183, 1) !important;
    text-decoration: none !important;
    font-weight: 450;
    transition: all .4s ease;
}

.top-menu a:hover {
    color: rgba(28, 112, 183, 0.7) !important;
    transition: all .4s ease;
}

.logo-top {
    height: 45px;
}

.top-menu {
    z-index: 16;
    position: fixed !important;
    width: 100%;
    box-shadow: 0 0 5px 0 #d0d0d0;
    background-color: #fff !important;
    padding: 0.5rem 10vw !important;
    height: 80px;
}

.nav-item.first-level {
    height: 80px;
}

.nav-item.first-level img {
    height: 30px;
}

.nav-item.first-level i {
    font-size: 25px;
}

.triangle {
    border-color: #00c7af transparent;
    border-style: solid;
    border-width: 0px 7px 7px 7px;
    height: 0px;
    width: 0px;
    position: absolute;
    top: -7px;
    left: 25px;
}

.links-center .nav-item.first-level:hover>a {
    color: #FF9951 !important;
    transition: all .1s ease !important;
}

.nav-item.first-level>a {
    height: 80px;
    width: 100% !important;
}

.links-center .nav-item.first-level>a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 1.5vw;
}


.dropdown-toggle::after {
    display: none !important;
}

.language-menu a.nav-link.dropdown-toggle {
    color: #FF7A7A !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.language-menu img {
    height: 20px;
    margin-right: 5px;
}

.language-menu i {
    color: #4D4D4D;
}

.dropdown-menu {
    padding: 20px 0 !important;
    border-radius: 7px !important;
    background-color: #F4F4F4 !important;
    border: none !important;
    border-bottom: 9px solid #00c7af !important;
    margin-top: -0.575rem !important;
}

.dropdown-menu a {
    color: rgba(77, 77, 77, 1) !important;
}

.dropdown-menu a:hover {
    color: rgba(77, 77, 77, 0.8) !important;
}

.btn-success-alternative,
.btn-blue-alternative {
    align-self: center;
    // width: 200px;   
    margin: 0 10px;
}

.btn-success-alternative a,
.btn-blue-alternative a {
    padding: 0 !important;
    color: #fff !important;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-success-alternative a:hover {
    color: rgba(0, 199, 175, 1) !important;
}

.btn-blue-alternative a:hover {
    color: rgba(33, 76, 219, 1) !important;
}

.no-border {
    border: none !important;
}


@media only screen and (min-width: 992px) and (max-width: 1460px) {

    .top-menu,
    .dropdown-item {
        font-size: 14px !important;
    }

    .nav-item.first-level {
        i {
            font-size: 20px !important;
        }

        span {
            font-size: 14px !important;
        }
    }

    .logo-top {
        height: 40px !important;
    }

    .links-center .nav-item.first-level>a {
        padding: 0 1.2vw !important;
    }
}

@media only screen and (max-width: 991px) {
    .triangle {
        display: none;
    }

    .top-menu {
        height: 60px;
        margin-top: 0;

        padding: 0.5rem 5vw !important;

        .logo-top {
            height: 30px;
        }

        .navbar-collapse {
            background-color: #f8f9fa;
            width: 100%;
            margin: 5px 0;
            padding: 10px 10px;
            border: 1px solid rgba(0, 0, 0, 0.1);

            .dropdown-menu {
                font-size: 14px !important;
                padding: 0 !important;
                margin-bottom: 20px;

                li {
                    height: 40px !important;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }

                img {
                    display: none !important;
                }
            }

            .links-center {
                .nav-item.first-level {
                    padding: 0 !important;
                    height: auto;

                    >a {
                        font-size: 18px !important;
                        font-weight: bold;
                        margin: 5px 0;
                        height: auto;
                        color: rgba(28, 112, 183, 1) !important;
                    }

                    &:hover {
                        border-bottom: 0;
                        margin-bottom: 0;

                        .dropdown-menu {
                            margin-top: 0 !important;
                        }
                    }
                }
            }

            .language-menu {
                flex-direction: row !important;
                align-items: center;
                justify-content: flex-end;

                .first-level {
                    height: 40px !important;
                }

                .dropdown-toggle {
                    height: 40px !important;
                    padding: 0 !important;
                    align-self: center;
                    justify-content: flex-end !important;
                }

                i {
                    display: none !important;
                }

            }

        }


    }


}
</style>