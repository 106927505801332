<template>
  <div style="width: 100%" :key="lang">
    <div class="section section-main-partner align-items-center">
      <h1 class="mb-4 title" v-html="$t('PARTNERSHIP_AGREEMENT')"></h1>
    </div>

    <div v-if="!error && window.width > 991" style="width: 100%" class="d-flex justify-content-end print-download">
      <a class="btn btn-blue mt-5" @click.stop="printMe()">{{
        $t("PDF_DOWNLOAD")
      }}</a>
    </div>

    <div class="documentation-padding mb-5">
      <div v-if="error" class="alert alert-danger w-100 mt-4" role="alert">
        {{ $t("ERROR_LOADING_AGREEMENT") }}
      </div>

      <div id="documentation-wrapper" class="documentation-wrapper" v-else-if="show" ref="printable"
        v-html="agreementContent" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import $ from "jquery";
import Axios from "axios";

export default {
  provide: function () {
    return {
      scrollto: this.scrollto,
    };
  },

  props: {
    partner_url: {
      type: String,
      default: "",
    },

    window: {
      type: Object,
      default() {
        return {
          width: 0,
          height: 0,
        };
      },
    },
  },

  computed: {
    ...mapState('MainStoreModule', {
      api: state => state.api
    }),

    locale() {
      return this.$i18n.locale;
    },

    agreementType() {
      return this.$route.params.agreementType ?? 'partner';
    },

    partnerData() {
      if (this.$route.params.data) {
        return JSON.parse(atob(this.$route.params.data));
      } else {
        return null;
      }
    }
  },

  data() {
    return {
      lang: "en",
      show: false,

      agreementContent: null,

      error: false
    };
  },

  watch: {
    locale(locale) {
      this.lang = locale == "pt_br" ? "pt" : locale;
    },
  },

  beforeCreate() {
    this.$emit("isPageLoaded", false);
  },

  mounted() {
    if (this.$route.params.lang) {
      this.changeLocale(this.$route.params.lang);
    }

    let locale = this.locale;
    if (locale === 'pt') {
      locale = 'pt_br';
    }

    let endpoint = this.api.baseUrl + '/api/v1/agreements/' + this.agreementType + '/render?language=' + locale
    
    if (this.partnerData && this.partnerData.pre_partner_id) {
      endpoint += '&pre_partner_id=' + this.partnerData.pre_partner_id;
    }

    Axios.get(endpoint)
      .then((response) => {
        this.agreementContent = response.data;
      })
      .catch((error) => {
        this.error = true;
        console.error(error);
      })
      .finally(() => {
        this.show = true;
        this.$emit("isPageLoaded", true);
      });
  },

  methods: {
    ...mapActions("MainStoreModule", ["setLocale"]),

    changeLocale(locale) {
      this.$i18n.locale = locale;
      this.setLocale(locale);
      this.$moment.locale(locale);
    },

    async printMe() {
      console.log('printing1')
      await this.$htmlToPaper('documentation-wrapper');
    }
  },
};
</script>

<style scoped lang="scss">
@media only screen and (max-width: 991px) {
  .section-main-partner {
    min-height: 200px;

    h1.title {
      font-size: 25px !important;
      width: 100% !important;
      text-align: center;
      padding: 20px 0 !important;
      margin: 0 !important;
    }
  }
}
</style>