<template>
  <div>
    <transition name="slide-fade">
      <div class="section section-main-plans">
        <div class="main-background"></div>

        <Plans v-if="show" :recurrence="recurrence" @toggleRecurrence="toggleRecurrence" />

        <!-- <ModulesResources v-if="show"></ModulesResources> -->

        <FAQ :key="$i18n.locale" v-if="show"></FAQ>

        <Testimonials></Testimonials>

        <Clients></Clients>

        <ThePlatform :window="window" v-if="show" />
      </div>
    </transition>
  </div>
</template>

<script>
import PlansMixin from '../../mixins/PlansMixin'
import Clients from '../common/Clients'
import Testimonials from '../common/Testimonials'
import ThePlatform from '../common/ThePlatform'
import FAQ from '../common/FAQ'
import Plans from '../plans/plans'
import ModulesResources from '../plans/modulesResources'

export default {
  mixins: [PlansMixin],
  components: {
    Testimonials,
    Clients,
    ThePlatform,
    FAQ,
    Plans,
    // ModulesResources
  },

  props: {
    window: {
      type: Object,
      default() {
        return {
          width: 0,
          height: 0
        }
      }
    },

    page: {
      type: String,
      default: ''
    },

    path: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      recurrence: 'year',
    }
  },

  beforeCreate() {
    this.$emit('isPageLoaded', false)
  },

  async created() {
    /*
      If currency from URL in case of a test
    */

    if (this.acceptCurrencies.includes(this.currency_by_url)) {
      this.setUserMonetary({ currency_by_url: this.currency_by_url })
    } else {
      if (!this.user_monetary)
        await this.setUserMonetaryByLocation()
    }

    if (!this.api.token) {
      await this.setApiToken()
    }

    if (this.api.token) {
      // await this.getAddons()
      await this.getSellablePlans({
        domain: this.domain.name,
        currency: this.user_monetary.currency_code,
        recurrence: this.recurrence
      })
    }
  },

  async mounted() {
    console.log(">> Plans page loaded");
    this.show = true
  },

  methods: {
    async toggleRecurrence() {
      this.recurrence = (this.recurrence === 'month') ? 'year' : 'month'

      await this.getSellablePlans({
        domain: this.domain.name,
        currency: this.user_monetary.currency_code,
        recurrence: this.recurrence
      })
    }
  }
}
</script>

<style lang="scss">
.testimonials-background {
  background: url(/assets/images/background-testimonials.png) no-repeat;
  background-size: 100% 100%;
}
</style>
