<template>
  <div>
    <transition name="slide-fade">
      <div>
        <div class="
            section section-main-partner
            d-flex
            align-items-center
            justify-content-center
          ">
          <h1 class="title" v-html="$t('PARTNER_TITLE').replace(/{domain}/g, domain_title)"></h1>
        </div>

        <div style="background-color: #fff; color: rgb(29, 113, 184); font-weight: 600;"
          class="section base-padding text-center lead">
          <span v-html="$t('PARTNERSHIP_LINE_30')" />
          <br />
          <br />
          <span v-html="$t('PARTNERSHIP_LINE_31')" />
        </div>

        <div class="section base-padding text-center lead" style="background-color: rgb(243, 246, 255);">
          <div class="row m-0 btns-partnership">
            <div class="col-sm-12 col-md-4 px-0">
              <span class="d-flex align-items-center justify-content-end">
                <a @click.stop="partnership = 'Authorized Partner'"
                  :class="{ 'btn-blue2-active': partnership == 'Authorized Partner' }"
                  class="btn btn-blue2 px-3 mx-2 py-4" style="border-radius: 30px!important; width: 300px">
                  Interfy Partner
                </a>
              </span>
            </div>

            <div class="col-sm-12 col-md-4 px-0">
              <span class="d-flex align-items-center justify-content-center">
                <a @click.stop="partnership = 'Private Label Partner'"
                  :class="{ 'btn-blue2-active': partnership == 'Private Label Partner' }"
                  class="btn btn-blue2 px-3 mx-2 py-4" style="border-radius: 30px!important; width: 300px">
                  Interfy Private Label
                </a>
              </span>
            </div>
            <div class="col-sm-12 col-md-4 px-0">
              <span class="d-flex align-items-center justify-content-start">
                <a class="btn btn-blue2 px-3 mx-2 py-4" style="border-radius: 30px!important; width: 300px"
                  href="https://admin.interfy.io" target="_blank">
                  Partner Login
                </a>
              </span>
            </div>
          </div>
        </div>

        <div class="section base-padding text-center lead"
          style="background-color: #fff; color: rgb(29, 113, 184)">
          <div v-if="partnership == 'Authorized Partner'" class="row m-0">
            <div class="col-sm-12 col-md-8">
              <h1 style="color: #1d71b8; font-size: 1.2vw !important" class="w-100 text-left" v-html="$t('PARTNERSHIP_LINE_08').replace(
                '{amount}',
                lang == 'pt' ? '6' : '4'
              )" />

              <ul class="list1 mt-5">
                <li class="d-flex align-items-start mb-4">
                  <img src="/assets/images/become-a-partner/check-mark.png" class="mr-2 icon-check" /><span
                    v-html="$t('PARTNERSHIP_LINE_14')"></span>
                </li>
                <li class="d-flex align-items-start mb-4">
                  <img src="/assets/images/become-a-partner/check-mark.png" class="mr-2 icon-check" /><span
                    v-html="$t('PARTNERSHIP_LINE_15')"></span>
                </li>

                <li class="d-flex align-items-start mb-4">
                  <img src="/assets/images/become-a-partner/check-mark.png" class="mr-2 icon-check" /><span
                    v-html="$t('PARTNERSHIP_LINE_10')"></span>
                </li>

                <!-- <li class="d-flex align-items-start mb-4">
                  <img src="/assets/images/become-a-partner/check-mark.png" class="mr-2 icon-check" /><span
                    v-html="$t('PARTNERSHIP_LINE_11')"></span>
                </li> -->
                <!-- <li class="d-flex align-items-start mb-4">
                  <img src="/assets/images/become-a-partner/check-mark.png" class="mr-2 icon-check" /><span
                    v-html="$t('PARTNERSHIP_LINE_12')"></span>
                </li> -->
                <li class="d-flex align-items-start mb-4">
                  <img src="/assets/images/become-a-partner/check-mark.png" class="mr-2 icon-check" /><span
                    v-html="$t('PARTNERSHIP_LINE_13')"></span>
                </li>

                <li class="d-flex align-items-start mb-4">
                  <img src="/assets/images/become-a-partner/check-mark.png" class="mr-2 icon-check" /><span
                    v-html="$t('PARTNERSHIP_LINE_001')"></span>
                </li>

                <!-- <li class="d-flex align-items-start mb-4">
                  <img src="/assets/images/become-a-partner/check-mark.png" class="mr-2 icon-check" /><span
                    v-html="$t('PARTNERSHIP_LINE_09')"></span>
                </li> -->

                <li class="d-flex align-items-start mb-4">
                  <img src="/assets/images/become-a-partner/check-mark.png" class="mr-2 icon-check" /><span
                    v-html="$t('PARTNERSHIP_LINE_32')"></span>
                </li>

                <li class="d-flex align-items-start">
                  <img src="/assets/images/become-a-partner/check-mark.png" class="mr-2 icon-check" /><span
                    v-html="$t('PARTNERSHIP_LINE_16')"></span>
                </li>

              </ul>
            </div>
            <div class="col-sm-12 col-md-4">
              <div class="h-100 w-100 d-flex align-items-center justify-content-center">
                <div class='dots-panel'>
                  <span v-for="dot in loop" :key="dot" class="dot background-bpm"></span>
                </div>

                <!-- <div class="modules-icons">
                  <div class="row">
                    <div class="col-6 p-0">
                      <div class="module-icon card-1">
                        <i alt="ECM" class="icon-ecm logo-ecm" />
                        <h2 class="font-ecm mt-2">ECM</h2>
                      </div>
                    </div>
                    <div class="col-6 p-0">
                      <div class="module-icon card-1">
                        <i alt="BPM" class="icon-bpm logo-bpm" />
                        <h2 class="font-bpm mt-2">BPM</h2>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-6 p-0">
                      <div class="module-icon card-1">
                        <i alt="SWC" class="icon-swc logo-swc" />
                        <h2 class="font-swc mt-2">SWC</h2>
                      </div>
                    </div>
                    <div class="col-6 p-0">
                      <div class="module-icon card-1">
                        <i alt="DSS" class="icon-dss logo-dss" />
                        <h2 class="font-dss mt-2">DSS</h2>
                      </div>
                    </div>
                  </div>

                  <template v-if="lang == 'pt'">
                    <div class="row">
                      <div class="col-6 p-0">
                        <div class="module-icon card-1">
                          <i alt="SLV" class="icon-slv logo-slv" />
                          <h2 class="font-slv mt-2">SLV</h2>
                        </div>
                      </div>
                      <div class="col-6 p-0">
                        <div class="module-icon card-1">
                          <i alt="DAS" class="icon-das logo-das" />
                          <h2 class="font-das mt-2">DAS</h2>
                        </div>
                      </div>
                    </div>
                  </template>
                </div> -->

              </div>
            </div>
          </div>

          <div v-if="partnership == 'Private Label Partner'" class="row m-0">
            <div class="col-sm-12">
              <div class='d-flex align-items-center justify-content-center' style="position: relative;">
                <img class="private-label-mock" src="/assets/images/become-a-partner/mockup_01_en.png"
                  alt="Have you ever thought about having a software platform with {amount} products with your brand?"
                  style="width: 70%;">
              </div>
            </div>

            <div class="col-sm-12">
              <h1 style="color: #154286; font-size: 1.5rem !important; font-weight: 600;"
                class="w-100 text-center mt-5 mb-5" v-html="$t('PARTNERSHIP_LINE_08').replace(
                  '{amount}',
                  lang == 'pt' ? '6' : '4'
                )" />
            </div>

            <div class="col-sm-12">
              <div class='d-flex align-items-center justify-content-start' style="position: relative;">
                <div class='dots-panel-2'>
                  <span v-for="dot in loop" :key="dot" class="dot background-bpm"></span>
                </div>

                <div class="modules-icons-2 w-100">
                  <div class="module-icon card-1">
                    <i alt="ECM" class="icon-ecm logo-ecm" />
                    <h2 class="font-ecm mt-2">ECM</h2>
                  </div>
                  <div class="module-icon card-1">
                    <i alt="BPM" class="icon-bpm logo-bpm" />
                    <h2 class="font-bpm mt-2">BPM</h2>
                  </div>
                  <div class="module-icon card-1">
                    <i alt="SWC" class="icon-swc logo-swc" />
                    <h2 class="font-swc mt-2">SWC</h2>
                  </div>
                  <div class="module-icon card-1">
                    <i alt="DSS" class="icon-dss logo-dss" />
                    <h2 class="font-dss mt-2">DSS</h2>
                  </div>

                  <template v-if="lang == 'pt'">
                    <div class="module-icon card-1">
                      <i alt="SLV" class="icon-slv logo-slv" />
                      <h2 class="font-slv mt-2">SLV</h2>
                    </div>
                    <div class="module-icon card-1">
                      <i alt="DAS" class="icon-das logo-das" />
                      <h2 class="font-das mt-2">DAS</h2>
                    </div>
                  </template>
                </div>
              </div>

            </div>
          </div>

          <div class="row m-0">
            <div class="col-sm-12">
              <div class="d-flex align-items-center justify-content-center">
                <a v-if="partnership == 'Authorized Partner'" @click="
                  openElement(
                    '.ct-form-partner-authorized',
                    'Authorized Partner',
                    'form-authorized-partner'
                  )
                  " class="btn btn-yellow px-3 mt-5 py-4" style="border-radius: 30px!important; width: 250px;"
                  v-html="$t('SUBMIT_APPLICATION')"></a>

                <a v-if="partnership == 'Private Label Partner'" @click="
                  openElement(
                    '.ct-form-partner-private-label',
                    'Private Label Partner',
                    'form-private-label'
                  )
                  " class="btn btn-yellow px-3 mt-5 py-4" style="border-radius: 30px!important; width: 250px;"
                  v-html="$t('SUBMIT_APPLICATION')"></a>
              </div>
            </div>
          </div>
        </div>

        <div v-if="partnership == 'Private Label Partner'" class="section base-padding d-flex flex-column w-100 domains"
          style="background-color: #e8f4f2">

          <div class="row mb-5">
            <div class="
                px-4
                col-lg-6 col-md-6 col-sm-12
                d-flex
                flex-column
                align-items-center
                justify-content-center
              ">
              <h2 style="color: #3b3c39; font-size: 1.25rem !important" class="mb-4">
                {{ $t("PARTNERSHIP_LINE_28") }}
              </h2>

              <img style="position: relative; width: 100%"
                :src="`/assets/images/become-a-partner/intellify_${lang}.png`" class="card-2" alt="Intellify" />

            </div>

            <div class="
                px-4
                col-lg-6 col-md-6 col-sm-12
                d-flex
                flex-column
                align-items-center
                justify-content-center
              ">
              <h2 style="color: #3b3c39; font-size: 1.25rem !important" class="mb-4">
                {{ $t("PARTNERSHIP_LINE_29") }}
              </h2>

              <img style="position: relative; width: 100%"
                :src="`/assets/images/become-a-partner/screenshot_${lang}.png`" class="card-2" alt="Intellify" />

            </div>
          </div>

          <h2 style="color: #1d71b8" class="mt-5 mb-4 w-100 text-center">
            {{ $t("PART_1") }}
          </h2>

          <div class="
              d-flex
              align-items-center
              justify-content-between
              flex-wrap
              w-100
              mb-5
            ">
            <img class="icon" :src="`/assets/images/domains/arquify/logo_01.svg`" />
            <img class="icon" :src="`/assets/images/domains/arquivodigitaldoc/logo_01.svg`" />
            <img class="icon" :src="`/assets/images/domains/trify/logo_01.svg`" />
            <img class="icon" :src="`/assets/images/domains/clouddoc/logo_01.svg`" />
            <img class="icon" :src="`/assets/images/domains/deketafy/logo_01.svg`" />
            <img class="icon" :src="`/assets/images/domains/dokercloud/logo_01.svg`" />
          </div>

          <div class="
              d-flex
              align-items-center
              justify-content-between
              flex-wrap
              w-100
              mb-5
            ">
            <img class="icon" :src="`/assets/images/domains/intellify/logo_01.svg`" />
            <img class="icon" :src="`/assets/images/domains/kmfy/logo_01.svg`" />
            <img class="icon" :src="`/assets/images/domains/neofy/logo_01.svg`" />
            <img class="icon" :src="`/assets/images/domains/organizefy/logo_01.svg`" />
            <img class="icon" :src="`/assets/images/domains/tecnewscorp/logo_01.svg`" />
            <img class="icon" :src="`/assets/images/domains/btool/logo_01.svg`" />
          </div>


        </div>

        <div v-if="partnership == 'Authorized Partner'" style="background-color: #f3f6ff;"
          class="section base-padding text-center">
          <h1 style="color: #233369; font-size: 1.2vw !important; font-weight: 550; padding: 0 10vw;" class="w-100 text-center mb-5"
            v-html="$t('PARTNERSHIP_LINE_17')"></h1>

          <!-- <div class="row platform-items">
            <div class="col-lg-6 col-md-6 col-sm-12 d-flex flex-column text-left">
              <h2 class="mb-3" v-if="lang == 'pt'" v-html="$t('PARTNERSHIP_LINE_18')"></h2>
              <h2 class="mb-3" v-html="$t('PARTNERSHIP_LINE_19')"></h2>
              <h2 class="mb-3" v-html="$t('PARTNERSHIP_LINE_20')"></h2>
              <h2 class="mb-3" v-html="$t('PARTNERSHIP_LINE_21')"></h2>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 d-flex flex-column text-left">
              <h2 class="mb-3" v-if="lang == 'pt'" v-html="$t('PARTNERSHIP_LINE_22')"></h2>
              <h2 class="mb-3" v-if="lang == 'pt'" v-html="$t('PARTNERSHIP_LINE_23')"></h2>
              <h2 class="mb-3" v-if="lang == 'pt'" v-html="$t('PARTNERSHIP_LINE_24')"></h2>
              <h2 v-html="$t('PARTNERSHIP_LINE_25')"></h2>
            </div>
          </div> -->

          <p class="mb-2 mt-3" style="font-size: 1rem !important; font-weight: 400;" v-html="$t('PARTNERSHIP_LINE_26')">
          </p>
        </div>

        <div class="section base-padding text-center lead"
          style="background-color: #fff; color: rgb(29, 113, 184); padding-bottom: 0!important;">
          <FAQ :key="`faq_authorized-partner${uniqueid}${lang}`" :partnership="'authorized-partner'"
            class="faq-partners" v-if="partnership == 'Authorized Partner'"></FAQ>

          <FAQ :key="`faq_private-label${uniqueid}${lang}`" :partnership="'private-label'" class="faq-partners"
            v-if="partnership == 'Private Label Partner'"></FAQ>

          <div class="row m-0">
            <div class="col-sm-12">
              <div class="d-flex align-items-center justify-content-center">

                <a v-if="partnership == 'Authorized Partner'" @click="
                  openElement(
                    '.ct-form-partner-authorized',
                    'Authorized Partner',
                    'form-authorized-partner'
                  )
                  " class="btn btn-yellow px-3 mt-5 py-4" style="border-radius: 30px!important; width: 250px;"
                  v-html="$t('SUBMIT_APPLICATION')"></a>

                <a v-if="partnership == 'Private Label Partner'" @click="
                  openElement(
                    '.ct-form-partner-private-label',
                    'Private Label Partner',
                    'form-private-label'
                  )
                  " class="btn btn-yellow px-3 mt-5 py-4" style="border-radius: 30px!important; width: 250px;"
                  v-html="$t('SUBMIT_APPLICATION')"></a>
              </div>
            </div>
          </div>
        </div>


        <div class="
            section section-certification
            d-flex
            flex-column
            justify-content-center
            align-items-center
          ">
          <h1 class="text-center font-bpm" v-html="$t('PROFESSIONA...')"></h1>
          <h2 v-html="$t('INTERFY PRO...').replace(/{domain}/g, domain_title)"></h2>

          <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-12">
              <img src="/assets/images/become-a-partner/icon-09.png" :alt="$t('EAD TRAININ...')" />
              <h3 v-html="$t('EAD TRAININ...')"></h3>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
              <img src="/assets/images/become-a-partner/icon-10.png" :alt="$t('RESPONSIVE ...')" />
              <h3 v-html="$t('RESPONSIVE ...')"></h3>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
              <img src="/assets/images/become-a-partner/icon-11.png" :alt="$t('DIGITAL CER...')" />
              <h3 v-html="$t('DIGITAL CER...')"></h3>
            </div>
          </div>
        </div>

        <div class="
            section section-allowed-companies
            d-flex
            flex-column
            justify-content-center
            align-items-center
          ">
          <h1 class="text-center font-bpm" v-html="$t('COMPANIES T...').replace(/{domain}/g, domain_title)"></h1>

          <div class="row mt-5">
            <div class="col-lg-3 col-md-3 col-sm-12">
              <img src="/assets/images/become-a-partner/icon-01.png" :alt="$t('SYSTEMS R...')" />
              <h3 v-html="$t('SYSTEMS R...')"></h3>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-12">
              <img src="/assets/images/become-a-partner/icon-02.png" :alt="$t('RESALE OF S...')" />
              <h3 v-html="$t('RESALE OF S...')"></h3>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-12">
              <img src="/assets/images/become-a-partner/icon-03.png" :alt="$t('IT CONSU...')" />
              <h3 v-html="$t('IT CONSU...')"></h3>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-12">
              <img src="/assets/images/become-a-partner/icon-04.png" :alt="$t('DOCUMENT GU...')" />
              <h3 v-html="$t('DOCUMENT GU...')"></h3>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12">
              <img src="/assets/images/become-a-partner/icon-05.png" :alt="$t('PRINT OUTSO...')" />
              <h3 v-html="$t('PRINT OUTSO...')"></h3>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-12">
              <img src="/assets/images/become-a-partner/icon-06.png" :alt="$t('COMPUTER PR...')" />
              <h3 v-html="$t('COMPUTER PR...')"></h3>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-12">
              <img src="/assets/images/become-a-partner/icon-07.png" :alt="$t('DOCUMENT TY...')" />
              <h3 v-html="$t('DOCUMENT TY...')"></h3>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-12">
              <img src="/assets/images/become-a-partner/icon-08.png" :alt="$t('RESALE OF D...')" />
              <h3 v-html="$t('RESALE OF D...')"></h3>
            </div>
          </div>
        </div>



        <!--
              Form Interfy partnership
            -->
        <div :key="`form-authorized-partner-${uniqueid}`" class="ct-form-partner-authorized hide">
          <FormPartnership v-if="formName == 'form-authorized-partner'" :partnership="partnership" :key="lang"
            :mode="'partner'"></FormPartnership>
        </div>

        <!--
              Form private label
            -->
        <div :key="`form-private-label-${uniqueid}`" class="ct-form-partner-private-label hide">
          <FormPartnership v-if="formName == 'form-private-label'" class="pt-5" :partnership="partnership" :key="lang"
            :mode="'partner'"></FormPartnership>
        </div>

      </div>
    </transition>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Clients from "../common/Clients";
import $ from "jquery";
import ThePlatform from "../common/ThePlatform";
import FormPartnership from "../become-a-partner/FormPartnership";
import FAQ from "../common/FAQPartnership";

export default {
  components: {
    // Clients,
    // ThePlatform,
    FormPartnership,
    FAQ,
  },
  props: {
    isDomainPermitted: {
      type: Boolean,
      default: false,
    },
    partner_url: {
      type: String,
      default: "",
    },
    window: {
      type: Object,
      default() {
        return {
          width: 0,
          height: 0,
        };
      },
    },
  },
  inject: ["scrollto"],
  data() {
    return {
      uniqueid: Math.floor(Math.random() * Math.floor(100)),
      show: false,
      formName: null,
      partnership: "Authorized Partner",
      domain_title: "Interfy",
    };
  },
  computed: {
    ...mapState("MainStoreModule", {
      domain: (state) => state.domain,
    }),

    loop() {
      var arr = []
      while (arr.length <= 6000) { //10000
        arr.push(arr.length)
      }

      return arr
    },

    privateLabelTableItems() {
      var items = [];

      for (var i = 1; i <= 17; i++) {
        items.push({
          label: this.$t(`label_private_label_${i}`),
          value: this.$t(`value_private_label_${i}`),
        });
      }

      return items;
    },

    lang() {
      return this.$i18n.locale == "pt_br" ? "pt" : this.$i18n.locale;
    },
  },

  watch: {
    partnership() {
      $(".ct-form-partner-authorized").addClass("hide");
      $(".ct-form-partner-private-label").addClass("hide");
      $(".table-partnership-wrapper").addClass("hide");

    },

  },
  methods: {
    ...mapActions("MainStoreModule", []),

    openElement(elem, partnership = null, formName = null) {
      this.partnership = partnership;
      this.formName = formName;

      console.log(">> Partership", partnership);

      switch (elem) {
        case ".ct-form-partner-authorized":
          $(".ct-form-partner-authorized").removeClass("hide");
          $(".ct-form-partner-private-label").addClass("hide");
          break;

        case ".ct-form-partner-private-label":
          $(".ct-form-partner-private-label").removeClass("hide");
          $(".ct-form-partner-authorized").addClass("hide");
          break;

        case ".table-partnership-wrapper":
          $(".table-partnership-wrapper").removeClass("hide");
          break;
      }

      this.scrollto(elem);
    },
  },

  beforeCreate() {
    this.$emit("isPageLoaded", false);
  },

  mounted() {
    this.domain_title =
      this.domain.type == "interfy" ? "Interfy" : this.domain.title;

    this.$root.$on("closePartnershipForm", (payload) => {
      console.log("close...");
      this.uniqueid = Math.floor(Math.random() * Math.floor(100));
      $(".ct-form-partner-private-label, .ct-form-partner-authorized").addClass(
        "hide"
      );
    });

    setTimeout(() => {
      this.$emit("isPageLoaded", true);
      this.show = true;
    }, 1000);
  },

  destroyed() {
    this.$root.$off(["closePartnershipForm"]);
  },
};
</script>

<style lang="scss">
.faq-partners {
  background-color: #2FB669 !important;
  border-radius: 30px;
  padding-bottom: 30px !important;

  h2 {
    color: #fff !important;
    text-align: center
  }

  li {
    border-top: 1.5px solid #fff !important;

    &:last-child {
      border-bottom: none !important;
    }

    a {
      color: #fff !important;
      flex-direction: column !important;
      justify-content: center !important;
      font-weight: bold;

      &:hover {
        color: #fff !important;
      }
    }

    span {
      color: #fff !important;
      text-align: center !important;
      font-weight: bold;
    }

    i {
      margin-top: 10px;
      color: #fff !important;
    }
  }
}
</style>
<style scoped lang="scss">
.module-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 35px;
  font-weight: bold;

  i {
    font-size: 45px;
  }

  &.white {

    h2,
    i {
      color: #fff;
    }
  }
}

.list1 {
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;

  li {
    color: #666;

    .icon-check {
      width: 20px;
    }

    span {
      font-size: 20px;
      line-height: 20px;
      text-align: left;
    }
  }
}

.domains {
  .icon {
    height: 50px;
  }
}

.card-2 {
  box-shadow: 7px 7px 6px rgba(0, 0, 0, 0.25), 0 2px 2px rgba(0, 0, 0, 0.22);
}
</style>


<style scoped lang="scss">
.become-a-partner {
  background-color: #f5f5ff;
}

.btns-partnership a,
a.btn-yellow {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

h6.title {
  width: 100%;
  text-align: center;
  margin: 20px 10px;
}

.table-partnership-wrapper {
  width: 100%;

  table {
    width: 80%;
    margin: 80px 0;

    background-color: #fff;

    tr {
      &:nth-child(1) {
        font-weight: bold;

        td {
          text-align: center;

          &:nth-child(1) {
            width: 70%;
          }
        }
      }

      td {
        border: 1px solid #2c2ffa;
        padding: 5px 15px;
      }
    }
  }
}

.section-partnership {
  padding: 6vw 10vw;
  margin-top: -18vw !important;

  h2.title {
    color: #4d4d4d;
    width: 60vw;
    text-align: center;
    font-weight: 600;
    margin-bottom: 130px;
  }

  div {
    h2 {
      margin-bottom: 30px;
    }

    p {
      width: 60%;
      text-align: center;
    }
  }
}

.section-partnership-program {
  padding-top: 4vw !important;
  padding-bottom: 4vw !important;

  .icon-partnership-center {
    width: 4%;
    height: 4%;
    float: left;
    margin-top: -6.1vw;
    margin-bottom: 2vw !important;
    position: relative;
    z-index: 9;
  }

  h1,
  h2 {
    width: 80%;
    text-align: center;
  }

  h2 {
    padding: 0 17vw;
    text-align: center;
  }

  .second-text {
    color: #1640c9;
  }

  .card {
    margin: 0 65px !important;
    width: 19rem !important;
    border-radius: 13px 13px 8px 8px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

    &:hover {
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.25), 0 2px 2px rgba(0, 0, 0, 0.22);
    }
  }

  .card-body {
    padding: 2.5rem !important;

    .card-text {
      color: #050505;
      font-weight: 400 !important;
      font-size: 20px !important;
      padding-bottom: 1rem !important;
      line-height: 1.5vw;
    }

    .btn-outline-blue {
      border-radius: 0.7rem !important;
      padding: 1.5rem 0 !important;
      font-size: 20px !important;
    }

    .btn-outline-success {
      border-radius: 0.7rem !important;
      padding: 1.5rem 0 !important;
      font-size: 20px !important;
    }
  }
}

.section-allowed-companies,
.section-certification {
  padding: 6vw 10vw;
  background-color: #f5f5ff;

  h2 {
    width: 100%;
    text-align: center;
    color: #4d4d4d;
    margin-bottom: 40px;
    font-size: 35px;
    font-weight: 600;
  }

  h3 {
    width: 100%;
    text-align: center;
    color: #4d4d4d;
    font-size: 20px;
  }

  .row {
    div {
      margin-bottom: 40px;
      text-align: center;

      img {
        height: 50px;
        margin-bottom: 20px;
      }
    }
  }
}

.section-certification {
  background-color: #fff;

  h1 {
    margin-bottom: 30px;
  }

  h2 {
    margin-bottom: 80px;
    padding: 0 20vw;
    text-align: center;
  }
}

.section-with-bg {
  background: url(/assets/images/become-a-partner/partners-top-bg.png) no-repeat;
  color: white;
}

@media only screen and (min-width: 1441px) {
  .section-partnership-program {
    .card {
      margin: 0 75px !important;
      width: 20rem !important;
      border: 0;

      .card-body {
        padding: 2rem !important;
      }
    }
  }
}

.private-label-mock {
  margin-top: -9vw;
  margin-bottom: 30px;
}

@media only screen and (max-width: 991px) {

  .private-label-mock {
    margin-top: 0 !important;
  }

  .btns-partnership {
    span {
      justify-content: center !important;
    }
  }

  .section-main-partner {
    min-height: 200px;

    h1.title {
      font-size: 25px !important;
      width: 100% !important;
      text-align: center;
      padding: 20px 0 !important;
      margin: 0 !important;
    }
  }

  .become-a-partner {
    padding: 0 0 0 0 !important;
  }

  .section-partnership-program,
  .section-allowed-companies,
  .section-certification {
    padding: 30px 10px;

    h1 {
      margin: 20px 0 20px 0 !important;
      padding: 0 !important;
      width: 100% !important;
      text-align: center;
      font-size: 20px !important;
    }

    h2 {
      margin: 0 !important;
      padding: 0 !important;
      width: 100% !important;
      text-align: center;
    }

    .btn {
      margin: 20px 0 40px 0 !important;
    }

    h2 {
      padding: 0 !important;
      margin: 0 0 20px 0 !important;
    }

    .row {
      div {
        margin: 0 0 20px 0 !important;
      }
    }
  }
}

.btn-blue2 {
  background-color: rgba(22, 138, 189, 1) !important;
  font-size: 1.2rem !important;
}

.btn-blue2:hover,
.btn-blue2-active {
  background-color: #164386 !important;
  color: #fff !important;
  font-weight: bold;
}

.btn-yellow {
  background-color: #F7EA52 !important;
  color: #666 !important;
  font-size: 1.1rem !important;
}

.btn-yellow:hover,
.btn-yellow-active {
  background-color: #5FC4EB !important;
  color: #fff !important;
}

.icon-prod {
  margin: 0;
  width: 90px !important;
  height: 70px !important;
  font-size: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  z-index: 2;

  span {
    background-repeat: no-repeat;
    background-size: 2vw 2vw;
    background-position: center;
    width: 6vw;
    height: 4.5vw;
    transition: all .4s ease;
    background-color: #FBFBFB;
    border-radius: 10px;
    margin-right: 1.5vw;
    margin-left: .5vw;

    &:hover {
      background-color: #2D5EFC;
    }
  }
}




.dots-panel {
  width: 60%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  right: 0;
  z-index: 1;
  line-height: 5px !important;

  .dot {
    line-height: 5px !important;
    opacity: 0.3;
    height: 4px;
    width: 4px;
    margin: 8px 8px;
    border-radius: 50%;
    display: inline-block;
  }
}


.dots-panel-2 {
  width: 40%;
  height: 200px;
  overflow: hidden;
  position: absolute;
  left: 0;
  z-index: 1;
  line-height: 5px !important;

  .dot {
    line-height: 5px !important;
    opacity: 0.3;
    height: 4px;
    width: 4px;
    margin: 8px 8px;
    border-radius: 50%;
    display: inline-block;
  }
}

.modules-icons {

  z-index: 2;
  position: absolute;
  right: 6vw;

  .module-icon {
    margin: 0;
    width: 100px !important;
    height: 80px !important;
    font-size: 40px;
    background-color: #fbfbfb;
    border-radius: 10px;
    margin: 20px;

    i {
      font-size: 35px;
      margin-top: 8px;
    }
  }
}

.modules-icons-2 {

  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;

  .module-icon {
    margin: 0;
    width: 100px !important;
    height: 80px !important;
    font-size: 40px;
    background-color: #fbfbfb;
    border-radius: 10px;
    margin: 20px;

    i {
      font-size: 35px;
      margin-top: 8px;
    }
  }
}

.platform-items {
  h2 {
    color: #233369 !important;
    font-weight: 400;
  }
}
</style>