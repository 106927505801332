import { mapState, mapActions } from 'vuex'
import $ from 'jquery'

export default {

    props: {
        window: {
            type: Object,
            default() {
                return {
                    width: 0,
                    height: 0
                }
            }
        },
        page: {
            type: String,
            default: ''
        },
        path: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            isSubmitting: false,
            isLoading: true,
            message: "Error...",
            variant: "danger",
            urlData: null,
            selectedPlan: {},
            slider_unit: 0,
            selected_group: {},
            partner_url: "",
            savedCustomer: null,

        }
    },

    computed: {

        ...mapState('MainStoreModule', {
            user_monetary: state => state.user_monetary,
            env: state => state.env,
            api: state => state.api,
            frontend: state => state.frontend,
            google: state => state.google,
            plans: state => state.plans,
            addons: state => state.addons,
            pre_customer: state => state.pre_customer,
            partner: state => state.partner,
            shopping_cart: state => state.shopping_cart,
            domain: state => state.domain
        }),

        lang() {
            return this.$i18n.locale == "pt_br" ? 'pt' : this.$i18n.locale
        },
    },

    methods: {
        ...mapActions('MainStoreModule', [
            'setApiToken',
            'getSellablePlans',
            'getPlan',
            'getAddons',
            'sendEmailConfirmation',
            'getPreCustomer',
            'setPreCustomer',
            'setPartnerByPreCustomer',
            'setLocale',
            'setEmailConfirmed',
            'updateShoppingCart',
            'saveCustomer',
            'saveWorkspace',
            'sendInterfyEmail',
            'clearShoppingCart'
        ]),

        formatPrice(price) {
            price = this.$n(price, 'currency', this.user_monetary.currency_code)
            return price
        },

        formatSellingMode(unit_amount, selling_mode) {
            if (unit_amount > 1) {
                selling_mode += "S"
            }

            return this.$t(selling_mode)
        },

        extractUrlData() {

            if (Object.keys(this.$route.params).length > 0 && this.$route.params.data) {

                try {

                    this.urlData = JSON.parse(atob(this.$route.params.data))
                    console.log(">> Checkout URL params", this.urlData)
                    this.changeLocale(this.urlData.customer.lang)

                } catch (error) {

                    this.$root.$emit('showAlert', {
                        bgVariant: "danger",
                        textVariant: "dark",
                        buttons: "ok-only",
                        title: this.$t('ERROR'),
                        message: error
                    })

                    this.$router.push({ name: "NotFound" })

                }


            } else {
                this.$router.push({ name: "NotFound" })
            }

        },

        changeLocale(locale) {
            this.$i18n.locale = locale
            this.setLocale(locale);
            this.$moment.locale(locale)
        },

        isValidCheckoutData() {

            if (
                Object.keys(this.$route.params).length == 0 ||
                !this.pre_customer ||
                (
                    this.pre_customer &&
                    this.urlData.parent &&
                    !this.partner)
            ) {
                return false

            } else {
                return true

            }

        },




        async getCheckoutData(parent = null) {
            this.isLoading = true

            if (this.urlData) {
                this.updateShoppingCart({ 'key': 'selected_addons', 'value': null })

                /*
                  Get pre-customer data
                */
                await this.getPreCustomer({ 'id': this.urlData.customer.id, 'email': this.urlData.customer.email }).then((response) => {
                    const customer = response
                    console.info(">> Response getPreCustomer", customer)

                    if (!customer.id) {
                        this.$router.push({ name: "NotFound" })
                    }

                    localStorage.setItem(this.env, JSON.stringify({ 'pre_customer': response }))

                    /*
                      Set partner if exists in customer object
                    */
                    if (customer.partner) {
                        this.setPartnerByPreCustomer(customer.partner)
                        this.partner_url = `/partner/${customer.partner.slug}`
                    }
                })

                /*
                  Confirm pre_customer e-mail
                */
                if (this.pre_customer && !this.pre_customer.email_confirmed) {
                    await this.setEmailConfirmed({ id: this.pre_customer.id }).then((response) => {
                        console.log(">> E-mail confirmation response", response)
                        localStorage.setItem('pre_customer', JSON.stringify(response))
                        this.setPreCustomer(response)
                    })
                }

                /*
                  Get Full plan data
                */
                await this.getPlan(this.pre_customer.plan_id).then((response) => {
                    this.selectedPlan = response
                    console.info(">> Selected plan", this.selectedPlan)
                })

                /*
                  Render selected plan
                */
                this.renderPlans({
                    'currency': (this.acceptCurrencies.includes(this.currency_by_url)) ? this.currency_by_url : this.user_monetary.currency_code,
                    'method': this.pre_customer.method
                }, [this.selectedPlan])




                if (parent != "start-free-checkout") {

                    /*
                        Get addons list
                    */
                    await this.getAddons()

                    this.renderAddons({
                        'currency': (this.acceptCurrencies.includes(this.currency_by_url)) ? this.currency_by_url : this.user_monetary.currency_code,
                        'recurrence': this.pre_customer.method
                    })

                }


            }

            this.isLoading = false

        },

        scrollto(elem) {

            var elemPos = $(elem).offset().top - $(this.$el).offset().top - 200

            $('html, body').animate({
                scrollTop: elemPos
            }, 1000)
        },

        checkFormValidity() {

            var t = this
            var v = true
            var scrolled = false

            $("#form-account, #form-payment, #formStartFreeCheckout").find('input, select, .vs__search').each(function () {

                const valid = this.checkValidity()
                const id = $(this).attr('id')
                const className = $(this).attr("class")

                for (var field in t.state) {
                    if (field == id || (field == 'country' && className == 'vs__search')) {
                        t.state[field] = valid
                    }
                }

                if (!valid) {
                    if (!scrolled) {
                        $(this).focus()
                    }

                    scrolled = true
                    v = valid
                }



            })

            console.log(this.state)

            return v

        },


        async verifyCreditCard() {

            var errors = 0

            return new Promise((resolve, reject) => {

                if (this.shopping_cart.payment.billing_method == 1) {

                    this.$root.$emit('validate-card')

                    setTimeout(() => {

                        if (!this.shopping_cart.payment.token) {
                            console.log(">> No stripe token", this.shopping_cart.payment.token)
                            errors++
                        }

                        return resolve(errors)

                    }, 1000)

                } else {

                    return resolve(errors)

                }


            })

        },

        async handleSubmit() {
            this.isSubmitting = true

            if (!this.checkFormValidity()) {
                this.isSubmitting = false
                return
            }

            /*
      
              Validate Credit Card

            */



            this.verifyCreditCard().then((response) => {

                var errors = response

                if (errors == 0) {

                    /*

                      Validate recaptcha
          
                    */

                    // if (this.env != 'localhost') {
                    if (!this.recaptcha) {
                        this.$root.$emit('showAlert', {
                            bgVariant: "warning",
                            textVariant: "dark",
                            buttons: "ok-only",
                            title: this.$t('WARNING'),
                            message: this.$t('SOLVE_RECAPTCHA')
                        })
                        errors++
                        this.isSubmitting = false
                        return
                    }
                    // }


                    if (errors == 0) {

                        /*
            
                          Submit Order

                        */

                        this.submit()

                    }

                } else {
                    this.isSubmitting = false
                }

            })

        },

        async submit() {

            var errors = 0,
                error_message = ''

            if (!this.savedCustomer) {

                /*
                  Save customer with pre_customer data
                */
                await this.saveNewCustomer()
                    .then((customer_response) => {
                        console.log(">> New customer saved", customer_response)
                        if (customer_response.id) {
                            this.savedCustomer = customer_response
                        } else {
                            errors++
                            error_message = "ERROR_CREATING_CUSTOMER"

                            return
                        }
                    }).catch((error) => {
                        console.log(">> Customer Error", error)

                        errors++
                        if ('code' in error.responseJSON && error.responseJSON.code === "DUPLICATED_CUSTOMER") {
                            error_message = "DUPLICATED_CUSTOMER"
                        } else {
                            error_message = "ERROR_CREATING_CUSTOMER"
                        }

                        return
                    })
            }

            if (errors == 0) {
                /*
                  Save the workspace
                */
                var workspace = null

                await this.saveNewWorkspace(this.savedCustomer).then((workspace_response) => {

                    if (workspace_response.id) {
                        workspace = workspace_response
                        // this.sendEmail(workspace, this.savedCustomer)

                        /*
                          Clean data for future customers            
                        */
                        this.clearShoppingCart()
                        this.setPreCustomer(null)
                        localStorage.removeItem(this.env)

                        const encryptedData = btoa(JSON.stringify({
                            "workspace_id": workspace.id,
                            "customer": {
                                email: this.savedCustomer.email
                            }
                        }))

                        this.$router.push({ name: "thanks-page", params: { data: encryptedData } })
                    } else {
                        errors++
                        error_message = "ERROR_CREATING_WORKSPACE"
                    }

                }).catch((error) => {
                    errors++
                    const errorType = error.responseJSON.type

                    if (errorType == "nameMustBeUnique") {
                        error_message = "WORKSPACE_NAME_MUST_BE_UNIQUE"
                    } else {
                        error_message = "ERROR_CREATING_WORKSPACE"
                    }

                })

            }

            if (errors > 0) {

                this.$root.$emit('showAlert', {
                    bgVariant: "danger",
                    textVariant: "dark",
                    buttons: "ok-only",
                    title: this.$t('ERROR'),
                    message: this.$t(error_message)
                })

                if (this.env != 'localhost') {
                    this.recaptchaReset()
                    this.$refs.recaptcha.reset() // Direct call reset method
                }
            }

            this.isSubmitting = false

        },

        async saveNewWorkspace(customer) {
            var addons = this.shopping_cart.selected_addons || []

            var data = {
                name: this.shopping_cart.account.workspace_name,
                locale: this.$i18n.locale,
                customer_id: customer.id,
                subscription: {
                    plan_id: this.shopping_cart.selected_plan.plan.id,
                    plan_price_id: this.shopping_cart.selected_plan.plan_price.id,
                    currency: this.shopping_cart.selected_plan.plan_price.currency,
                    trial: this.pre_customer.isTrial,
                    charge_mode: "auto",
                    // payment_method: this.shopping_cart.payment.billing_method,
                    // addons: addons.map((addon) => {
                    //     return {
                    //         id: addon._id,
                    //         tier_id: (addon.tiered) ? addon.selectedTier._id : null,
                    //         quantity: 1
                    //     }
                    // })
                },
                admin_user: {
                    email: this.shopping_cart.account.email,
                    password: this.shopping_cart.account.password,
                }
            }

            return await this.saveWorkspace(data).then((response) => {
                console.log(">> New workspace saved", response)
                return response
            }).catch((error) => {
                return error
            })

        },

        async saveNewCustomer() {
            var data = {
                name: this.shopping_cart.account.name,
                currency: this.user_monetary.currency_code,
                partner_id: this.pre_customer.partner_id,
                phone1: this.shopping_cart.account.customer_phone,
                company_type: this.pre_customer.company_type,
                email: this.shopping_cart.account.email,
                trading_name: this.shopping_cart.payment.trading_name,
                tax_id: this.shopping_cart.payment.tax_id,
                address_line1: this.shopping_cart.payment.address_line1,
                address_line2: this.shopping_cart.payment.address_line2,
                city: this.shopping_cart.payment.city,
                province: this.shopping_cart.payment.province,
                country: this.shopping_cart.payment.country,
                zip_code: this.shopping_cart.payment.zip_code,
                email_confirmed: this.pre_customer.email_confirmed
            }

            if (this.shopping_cart.payment.token) {
                data['card_token'] = this.shopping_cart.payment.token.id
                data['card_last_four'] = this.shopping_cart.payment.token.last4
                data['card_brand'] = this.shopping_cart.payment.token.brand
                data['replace_credit_card'] = true
            }

            return this.saveCustomer(data)
        },

        // async sendEmail(workspace, customer) {

        //     await this.sendInterfyEmail({

        //         view: "workspaceCreated",
        //         subject: "Nova Workspace Criada!",
        //         locale: this.$i18n.locale,

        //         workspace: workspace,
        //         customer: customer,
        //         shopping_cart: this.shopping_cart

        //     }).then((response) => {
        //     })

        // },


    },

}