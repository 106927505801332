<template>
  <div>
    <b-overlay :show="isLoading" rounded="sm">
      <form id="formCustomerData" ref="formCustomerData" @submit.stop.prevent="handleSubmit">
        <div>
          <div class="row">
            <div class="col-12">
              <b-form-group :label="$t('FULL_NAME')" :state="state.name" label-for="name" :invalid-feedback="$t('FIELD_IS_REQUIRED').replace('{var1}', $t('FULL_NAME'))
                ">
                <b-form-input :state="state.name" id="name" v-model="form.name" required></b-form-input>
              </b-form-group>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6 col-md-12 col-sm-12">
              <b-form-group :label="'E-mail'" :state="state.email" label-for="email" :invalid-feedback="$t('FIELD_IS_REQUIRED').replace('{var1}', 'E-mail')
                ">
                <b-form-input id="email" :state="state.email" v-model="form.email" type="email" required></b-form-input>
              </b-form-group>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12">

              <b-form-group :label="$t('PHONE')" :state="state.phone" label-for="phone" :invalid-feedback="$t('FIELD_IS_REQUIRED').replace('{var1}', $t('PHONE'))
                ">
                <div class="d-flex flex-row">

                  <vue-tel-input :key="lang" id="phone" required :state="state.phone" v-model="form.phone"
                    :allCountries="getCountries()" :preferredCountries="['BR', 'US']" :autoformat="true"
                    :validCharactersOnly="true" :autoDefaultCountry="true" :inputOptions="{
                      placeholder: '',
                      required: true
                    }" @country-changed="phoneCountryChanged"></vue-tel-input>

                </div>
              </b-form-group>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6 col-md-12 col-sm-12">
              <b-form-group :label="$t('COMPANYS NAME')" :state="state.company_name" label-for="company_name"
                :invalid-feedback="$t('FIELD_IS_REQUIRED').replace('{var1}', $t('COMPANYS NAME'))">
                <b-form-input :state="state.company_name" id="company_name" v-model="form.company_name"
                  required></b-form-input>
              </b-form-group>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12">
              <b-form-group :label="$t('COMPANY_TYPE')" :state="state.company_type" label-for="company_type"
                :invalid-feedback="$t('FIELD_IS_REQUIRED').replace('{var1}', $t('COMPANY_TYPE'))">
                <b-form-select :state="state.company_type" id="company_type" v-model="form.company_type"
                  :options="companyTypesList" required></b-form-select>
              </b-form-group>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-4 col-md-12 col-sm-12">
              <b-form-group :label="$t('SEGMENT')" :state="state.company_segment" label-for="company_segment"
                :invalid-feedback="$t('FIELD_IS_REQUIRED').replace('{var1}', $t('SEGMENT'))
                  ">
                <b-form-select :state="state.company_segment" id="company_segment" v-model="form.company_segment"
                  :options="segmentsList" required></b-form-select>
              </b-form-group>
            </div>

            <div class="col-lg-4 col-md-12 col-sm-12">
              <b-form-group :label="$t('POSITION')" :state="state.role" label-for="role" :invalid-feedback="$t('FIELD_IS_REQUIRED').replace('{var1}', $t('POSITION'))
                ">
                <b-form-select :state="state.role" id="role" v-model="form.role" :options="rolesList"
                  required></b-form-select>
              </b-form-group>
            </div>

            <div class="col-lg-4 col-md-12 col-sm-12">
              <b-form-group :label="$t('EMPLOYEES')" :state="state.company_employees" label-for="company_employees"
                :invalid-feedback="$t('FIELD_IS_REQUIRED').replace('{var1}', $t('EMPLOYEES'))
                  ">
                <b-form-select :state="state.company_employees" id="company_employees" v-model="form.company_employees"
                  :options="company_employeesList" required></b-form-select>
              </b-form-group>
            </div>
          </div>

          <!-- 

              LOCATION

            -->

          <div class="row">
            <!-- country -->
            <div class="col-lg-4 col-md-4 col-sm-12">
              <b-form-group id="input-group-9" :label="$t('country')" label-for="country">
                <v-select :disabled="isLoadingZipcode" v-if="countriesList" id="country" v-model="form.country"
                  :options="countriesList">
                  <template #search="{ attributes, events }">
                    <input class="vs__search" :required="!form.country" v-bind="attributes" v-on="events" />
                  </template>
                </v-select>
              </b-form-group>
            </div>

            <!-- zip_code -->
            <div class="col-lg-3 col-md-3 col-sm-12">
              <b-form-group id="input-group-5" :key="`zipcode_${form.country.code}`" :label="$t('zip_code')"
                label-for="zip_code">
                <b-form-input :disabled="isLoadingZipcode" v-if="form.country && form.country.code == 'BRA'"
                  v-mask="'#####-###'" id="zip_code" v-model="form.zip_code" @change="handleZipCode" trim></b-form-input>
                <b-form-input :disabled="isLoadingZipcode" v-else-if="form.country && form.country.code == 'USA'"
                  v-mask="'#####-####'" id="zip_code" v-model="form.zip_code" trim></b-form-input>
                <b-form-input :disabled="isLoadingZipcode" v-else id="zip_code" v-model="form.zip_code"
                  trim></b-form-input>
              </b-form-group>
            </div>

            <!-- address_line1 -->
            <div class="col">
              <b-form-group id="input-group-3" :label="$t('address_line1')" label-for="address_line1">
                <b-form-input id="address_line1" v-model="form.address_line1" :disabled="isLoadingZipcode"></b-form-input>
              </b-form-group>
            </div>

          </div>

          <div class="row">

            <!-- address_line2 -->
            <div class="col-lg-3 col-md-3 col-sm-12">
              <b-form-group id="input-group-4" :label="$t('address_line2')" label-for="address_line2">
                <b-form-input id="address_line2" v-model="form.address_line2" :disabled="isLoadingZipcode"></b-form-input>
              </b-form-group>
            </div>

            <!-- city -->
            <div class="col-lg-3 col-md-3 col-sm-12">
              <b-form-group id="input-group-8" :label="$t('city')" label-for="city">
                <b-form-input id="city" v-model="form.city" :disabled="isLoadingZipcode"></b-form-input>
              </b-form-group>
            </div>

            <!-- county -->
            <div class="col-lg-3 col-md-3 col-sm-12">
              <b-form-group id="input-group-7" :label="$t('county')" label-for="county">
                <b-form-input id="county" v-model="form.county" :disabled="isLoadingZipcode"></b-form-input>
              </b-form-group>
            </div>

            <!-- province -->
            <div class="col-lg-3 col-md-3 col-sm-12">
              <b-form-group id="input-group-6" :label="$t('province')" label-for="province">
                <b-form-input id="province" v-model="form.province" :disabled="isLoadingZipcode"></b-form-input>
              </b-form-group>
            </div>

          </div>

          <!-- RECAPTCHA -->
          <div class="row">
            <div class="col div-recaptcha mt-3">
              <vue-recaptcha ref="recaptcha" @verify="recaptchaOnVerify" @expired="recaptchaOnExpired"
                :sitekey="domain.googlerecaptcha_key" :loadRecaptchaScript="true">
              </vue-recaptcha>
            </div>
          </div>

        </div>
      </form>
    </b-overlay>

    <div v-if="showCustomModal" class="custom-modal">

      <div class="content">
        <div class="header" v-html="$t('EMAIL_CONFIRMATION').replace('{domain}', domain.title)"></div>
        <div class="body"
          v-html="$t('CHECK_INBOX').replace('{var1}', `<span style='color: #1d70b7;'>${confirmationEmail}</span>`)"></div>
        <div class="footer">
          <button class='btn btn-success' @click.stop="showCustomModal = false">{{ $t('CLOSE') }}</button>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import RecaptchaMixin from "../../mixins/RecaptchaMixin"
import countriesJson from '../../../public/assets/json/countries.json'
import $ from "jquery"

export default {
  mixins: [RecaptchaMixin],
  components: {
  },
  props: ['selectedPlan'],
  computed: {
    ...mapState("MainStoreModule", {
      user_monetary: (state) => state.user_monetary,
      api: (state) => state.api,
      google: (state) => state.google,
      plans: (state) => state.plans,
      addons: (state) => state.addons,
      partner: (state) => state.partner,
      domain: state => state.domain,
      env: state => state.env
    }),
    lang() {
      return this.$i18n.locale == "pt_br" ? 'pt' : this.$i18n.locale
    }
  },

  watch: {
    lang(lang) {
      this.formatSegments()
      this.formatroles()
      this.formatCompanyTypes()
    },

    watch: {
      form: {
        deep: true,
        handler(form) {
          this.setCompanyIdentification()
        }
      }
    },
    user_monetary: {
      deep: true,
      handler() {

        this.brLocaleValidation = this.user_monetary.ioc == 'br'
        this.enLocaleValidation = this.user_monetary.ioc == 'us'

        setTimeout(() => {
          if (this.brLocaleValidation) {
            this.form.country = { code: "BRA", label: "Brasil" }

          } else if (this.enLocaleValidation) {
            this.form.country = { code: "USA", label: "United States" }
          }
        }, 200)
      }
    }
  },
  data() {
    return {
      showFacebookPixel: false,
      isLoadingZipcode: false,
      brLocaleValidation: null,
      enLocaleValidation: null,
      showCustomModal: false,
      countries: countriesJson,
      countriesList: null,
      countriesJson: countriesJson,

      isLoading: false,
      confirmationEmail: null,
      state: {
        name: null,
        email: null,
        phone: null,
        role: null,
        company_name: null,
        company_type: null,
        company_segment: null,
        company_employees: null,
        zip_code: null,
        address_line1: null,
        address_line2: null,
        county: null,
        city: null,
        province: null,
        country: null,
      },
      form: {
        name: "",
        email: "",
        phone: "",
        role: "",
        company_name: "",
        company_type: "",
        company_segment: "",
        company_employees: "",
        zip_code: "",
        address_line1: "",
        address_line2: "",
        county: "",
        city: "",
        province: "",
        country: "",
      },
      rolesList: [],
      roles: [
        {
          pt: "Administrativo / Financeiro",
          en: "Administrative / Financial",
          es: "Administrativo / Financiero",
        },
        {
          pt: "Recursos humanos",
          en: "Human resources",
          es: "Recursos humanos",
        },
        {
          pt: "TI / Desenvolvimento de sistemas",
          en: "IT / Software development",
          es: "TI / Desarrollo de sistemas",
        },
        {
          pt: "Marketing",
          en: "Marketing",
          es: "Marketing",
        },
        {
          pt: "Operações",
          en: "Operations",
          es: "Operaciones",
        },
        {
          pt: "Proprietário / CEO",
          en: "Owner / CEO",
          es: "Dueño / CEO",
        },
        {
          pt: "Controle de vendas",
          en: "Sales control",
          es: "Control de ventas",
        },
        {
          pt: "Controle de qualidade",
          en: "Quality control",
          es: "Control de calidad",
        },
        {
          pt: "Vendas",
          en: "Sales",
          es: "Ventas",
        },
        {
          pt: "Outro",
          en: "Other",
          es: "Otro",
        },
      ],
      companyTypesList: [],
      companyTypes: [
        {
          pt: "MEI, micro e pequenas empresas",
          en: "Micro and small companies",
          es: "Micro y pequeñas empresas",
        },
        {
          pt: "Médias empresas",
          en: "Medium-sized companies",
          es: "Empresas medianas",
        },
        {
          pt: "Grandes empresas",
          en: "Big enterprises",
          es: "Grandes empresas",
        },
      ],
      company_employeesList: [
        { value: "1-10", text: "1-10" },
        { value: "11-50", text: "11-50" },
        { value: "51-200", text: "51-200" },
        { value: "201-500", text: "201-500" },
        { value: "501-1000", text: "501-1000" },
        { value: "1001-5000", text: "1001-5000" },
        { value: "5001-10000", text: "5001-10000" },
        { value: "10000+", text: "10000+" },
      ],
      segmentsList: [],
      segments: [
        {
          pt: "Construção",
          en: "Construction",
          es: "Construcción",
        },
        {
          pt: "Consultoria",
          en: "Consultancy",
          es: "Consultoría",
        },
        {
          pt: "Educação",
          en: "Education",
          es: "Educación",
        },
        {
          pt: "Energia",
          en: "Energy",
          es: "Energía",
        },
        {
          pt: "Serviços financeiros",
          en: "Financial services",
          es: "Servicios financieros",
        },
        {
          pt: "Saúde",
          en: "Cheers",
          es: "Salud",
        },
        {
          pt: "Jurídico",
          en: "Legal",
          es: "Legal",
        },
        {
          pt: "Manufatura",
          en: "Manufacturing",
          es: "Fabricación",
        },
        {
          pt: "Marketing",
          en: "Marketing",
          es: "Márketing",
        },
        {
          pt: "Organização não-governamental",
          en: "Non-governmental organization",
          es: "Organización no gubernamental",
        },
        {
          pt: "Vendas",
          en: "Sales",
          es: "Ventas",
        },
        {
          pt: "Turismo",
          en: "Tourism",
          es: "Turismo",
        },
        {
          pt: "Tecnologia",
          en: "Technology",
          es: "Tecnología",
        },
        {
          pt: "Telecomunicações",
          en: "Telecommunications",
          es: "Telecomunicaciones",
        },
        {
          pt: "Transporte",
          en: "Transport",
          es: "Transporte",
        },
        {
          pt: "Outro",
          en: "Other",
          es: "Otro",
        },
      ],
    }
  },

  methods: {
    ...mapActions("MainStoreModule", [
      "sendEmailConfirmation",
      "savePreCustomer",
      "getPreCustomerByEmail",
      "editPreCustomer",
      "getCepData"
    ]),

    phoneCountryChanged(e) {
      //
    },

    async handleZipCode() {

      const zipcode = this.form.zip_code.replace(/_/g, '').replace(/ /g, '')

      if (zipcode.length < 9) {
        return
      }

      this.isLoadingZipcode = true

      await this.getCepData(zipcode).then(response => {

        const data = response

        if (data.erro) {

          this.form.county = null
          this.form.address_line2 = null
          this.form.city = null
          this.form.address_line1 = null
          this.form.province = null

        } else {

          this.form.county = data.bairro
          this.form.address_line2 = data.complemento
          this.form.city = data.localidade
          this.form.address_line1 = data.logradouro
          this.form.province = data.estado
          this.form.country = { code: "BRA", label: "Brasil" }

        }

      }).finally(() => {
        this.isLoadingZipcode = false
      })

    },

    isValidZipCode(str) {

      str = str.replace(/_/g, '').replace(/ /g, '')

      if (
        str == '' ||
        str == null ||
        (str.length < 9 && this.country.code == 'Brazil') ||
        (str.length < 10 && this.country.code == 'United States')
      ) {
        return false
      }

      return true

    },

    setCompanyIdentification() {

      var identification = {},
        country_code = this.form.country ? this.form.country.code : null

      if (country_code == 'MOZ') {

        identification = {
          title: "NUIT",
          id_mask: "#########",
          zipcode_mask: "####"
        }

      } else if (country_code == 'BRA') {

        identification = {
          title: "CNPJ",
          id_mask: "##.###.###/####-##",
          zipcode_mask: "#####-###"
        }

      } else if (country_code == 'USA') {

        identification = {
          title: "EIN",
          id_mask: "##-#######",
          zipcode_mask: "#####"
        }

      } else {

        identification = {
          title: this.$t('company_identification'),
          id_mask: "##########################",
          zipcode_mask: "##########"
        }
      }

      return this.company_identification = identification
    },

    setCountriesList() {

      var countriesList = []

      for (var i in this.countriesJson) {
        var country = this.countriesJson[i]

        var name = null,
          code = country['cca3']

        if (this.lang == 'en') {
          name = country['name']['common']
        } else {
          name = country['translations'][this.lang]['common']
        }

        countriesList.push({
          code: code,
          label: this.$t(`${name}`)
        })

      }

      this.countriesList = countriesList
    },

    reset() {
      this.form = {
        name: "",
        email: "",
        phone: "",
        role: "",
        company_name: "",
        company_type: "",
        company_segment: "",
        company_employees: "",
        zip_code: "",
        address_line1: "",
        address_line2: "",
        county: "",
        city: "",
        province: "",
        country: "",
      },
        this.state = {
          name: null,
          email: null,
          phone: null,
          role: null,
          company_name: null,
          company_type: null,
          company_segment: null,
          company_employees: null,
          zip_code: null,
          address_line1: null,
          address_line2: null,
          county: null,
          city: null,
          province: null,
          country: null,
        }
    },

    getCountries() {

      var countries = []

      for (var i in this.countries) {
        var country = this.countries[i]
        var name = null

        if (this.lang == 'en') {
          name = country['name']['common']
        } else {
          name = country['translations'][this.lang]['common']
        }

        countries.push({
          name: name,
          iso2: country.cca2,
          dialCode: country.callingCode[0],
          priority: 0,
          areaCodes: null
        })
      }

      return countries
    },

    onSelect({ name, iso2, dialCode }) {
      console.log(name, iso2, dialCode);
    },

    formatSegments() {
      this.segmentsList = []
      for (var i in this.segments) {
        var name = this.segments[i][this.lang]

        this.segmentsList.push({
          value: name,
          text: this.$t(`${name}`),
        })
      }
    },
    formatCompanyTypes() {
      this.companyTypesList = []
      for (var i in this.companyTypes) {
        var name = this.companyTypes[i][this.lang]

        this.companyTypesList.push({
          value: name,
          text: this.$t(`${name}`),
        })
      }
    },
    formatroles() {
      this.rolesList = []
      for (var i in this.roles) {
        var name = this.roles[i][this.lang]

        this.rolesList.push({
          value: name,
          text: this.$t(`${name}`),
        })
      }
    },

    checkFormValidity() {
      var t = this
      var v = true

      $("#formCustomerData").find("input, select, .vti__input").each(function () {
        const valid = this.checkValidity()
        const id = $(this).attr("id")
        const className = $(this).attr("class")

        for (var field in t.state) {
          if (field == id || (field == 'phone' && className == 'vti__input')) {
            t.state[field] = valid
          }
        }

        if (!valid) {
          v = valid
        }
      })

      return v
    },

    handleOk(e) {
      e.preventDefault()
      this.handleSubmit()
      let el = document.querySelector(':focus')
      if (el) el.blur()
    },

    async handleSubmit() {

      if (!this.checkFormValidity()) {
        this.$emit('error', false)
        return
      }

      setTimeout(async () => {

        if (!this.recaptcha) {
          this.$root.$emit("showAlert", {
            bgVariant: "warning",
            textVariant: "dark",
            buttons: "ok-only",
            title: this.$t("WARNING"),
            message: this.$t("SOLVE_RECAPTCHA"),
          })
          this.$emit('error', false)
          return
        }

        this.recaptchaReset()
        this.$refs.recaptcha.reset() // Direct call reset method

        this.isLoading = true

        this.form.country = this.form.country.code

        /*
            
            Check if pre_customer already exists

        */
        await this.getPreCustomerByEmail({ email: this.form.email }).then(async (response) => {

          var data = {
            ...this.form,
            ...this.selectedPlan,
            ...{ domain_name: this.domain.name }
          }

          if (this.partner) {
            data.partner_id = this.partner.id
          } else if (this.domain.type != 'interfy' && this.domain.parent_id) {
            data.partner_id = this.domain.parent_id
          }

          if (response.length == 0) {

            await this.savePreCustomer(data).then((res) => {
              console.log(">> New Pre customer", res)
              this.sendEmail(res)
            })

          } else {

            console.log(">> Already a Pre customer", response)
            await this.editPreCustomer({ id: response.id, data: data }).then((response) => {
              this.sendEmail(response)
            })
          }

          this.showFacebookPixel
        })

      }, 500)
    },

    async sendEmail(pre_customer) {
      pre_customer.view = "emailConfirmation"
      pre_customer.subject = this.$t("EMAIL_CONFIRMATION").replace("{domain}", this.domain.title)

      let linkParams = {
        customer: {
          id: pre_customer.id,
          email: pre_customer.email,
          lang: this.$i18n.locale == "pt_br" ? "pt" : this.$i18n.locale,
        },
      }

      if (this.partner) {
        pre_customer.partner = this.partner
        pre_customer.partner_id = this.partner.id

        linkParams.partner = {
          id: this.partner.id,
          slug: this.partner.slug,
          billing_email: this.partner.billing_email
        }
      }

      let encryptedData = btoa(JSON.stringify(linkParams))
      
      pre_customer.confirmationLink = `${window.location.origin}/start-free/checkout/${encryptedData}`
      pre_customer.locale = this.$i18n.locale

      await this.sendEmailConfirmation(pre_customer).then((response) => {
        if (response.sent) {

          this.$emit('closeModal')
          this.confirmationEmail = this.form.email
          this.showCustomModal = true

          this.reset()
        }
      }).catch((error) => {
        console.log(">> Error: ", error)

        this.$root.$emit("showAlert", {
          bgVariant: "danger",
          textVariant: "light",
          buttons: "ok-only",
          title: this.$t("ERROR"),
          message:
            this.$t("EMAIL_VERIFICATION_ERROR") +
            "<br><br><small><b>Error message: </b>" +
            error.responseJSON.message +
            "</small>",
        })
      })

      this.$emit('onSave')
      this.isLoading = false
    },
  },

  mounted() {
    this.formatSegments()
    this.formatroles()
    this.formatCompanyTypes()
    this.setCountriesList()

    this.$root.$on('on-hit-ok-modal-customer', async () => {
      this.handleSubmit()
    })

    this.$root.$on('on-hit-ok-start-free', async () => {
      this.handleSubmit()
    })
  },

  destroyed() {
    this.$root.$off(['on-hit-ok-modal-customer', 'on-hit-ok-start-free'])
  },
}
</script>

<style scoped lang="scss">
.custom-modal {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999999;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .content {

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    width: 500px;
    min-height: 300px;
    border-radius: 10px;
    padding: 30px;
    background-color: #fff;

    .header {
      font-size: 20px;
      color: #1d70b7;
      width: 100%;
      text-align: center;
      border-bottom: 3px solid #eef3fc;
      padding-bottom: 30px;
    }

    .body {
      padding: 30px 0;
      width: 100%;
      text-align: center;
    }

    .footer {
      border-top: 3px solid #eef3fc;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 30px;

      .btn {
        padding-right: 20px !important;
        padding-left: 20px !important;
      }
    }
  }

}

@media only screen and (max-width: 991px) {
  #formCustomerData {
    .row {
      margin: 0 !important;
    }

    .div-recaptcha {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 15px 0 !important;
    }
  }
}
</style>