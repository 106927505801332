<template>
  <div class="ct-form-checkout mt-4">

    <form id="form-selected-plan" ref="form-selected-plan">

      <div class="d-flex flex-row justify-content-around align-items-center">

        <!-- 

          Selected Plan + modules

        -->
        <div class="block-plan">
          <h2>{{ renderedPlan.type }}</h2>
          <img :src="'/assets/images/plans/' + renderedPlan.type + '.png'" alt="">
          <h2>{{ renderedPlan.modules }}</h2>
        </div>

        <!-- 

          Addons included

        -->
        <div class="block-some-addons">
          <ul>
            <template v-for="(addon, index) in renderedAddons['GENERAL']">
              <li v-if="showAddon(addon, index)" :key="index"><i class="fas fa-check"></i> {{ addon.name[lang] }} </li>
            </template>
            <li class="mt-3">
              <router-link target="_blank" :to="`/plans${partner_url}`"><small>{{ $t('SEE_WHAT_IS_INCLUDED')
                  }}</small></router-link>
            </li>
          </ul>
        </div>

        <!-- 

          Pricing

        -->
        <div class="block-pricing">

          <div class="line-01" v-if="method == 'credit-card'">
            <label>{{ $t('FROM') }}: {{ user_monetary.symbol }} {{ renderedPlan.initialPrice[0] +
              user_monetary.separators.cents +
              renderedPlan.initialPrice[1]}}</label>
            <span>{{ renderedPlan.discount }}%</span>
          </div>
          <div class="line-02">
            <label v-if="method == 'credit-card'">{{ $t('TO') }}: {{ user_monetary.symbol }}</label>
            <label v-if="method == 'manual'"> {{ user_monetary.symbol }}</label>
            <div>
              <h2 v-if="method == 'credit-card'">{{ renderedPlan.finalPrice[0] }}</h2>
              <h2 v-if="method == 'manual'">{{ renderedPlan.initialPrice[0] }}</h2>
            </div>
            <label v-if="method == 'credit-card'">{{ user_monetary.separators.cents +
              renderedPlan.finalPrice[1]}}</label>
            <label v-if="method == 'manual'">{{ user_monetary.separators.cents + renderedPlan.initialPrice[1] }}</label>
          </div>
          <div class="line-03">
            <label>{{ $t(renderedPlan.sellingMode) }} / {{ $t("MONTH") }}</label>
          </div>

        </div>

      </div>

      <!-- 

        Unit selector

      -->

      <Range class="mt-4" :type="'normal'" :thumb_color="'#3150E6'" :selectedPlan="selectedPlan"
        :renderedPlan="renderedPlan">
      </Range>

    </form>


    <div class="d-flex flex-row align-items-center mt-5"
      :class="{ 'justify-content-between': isTrial(), 'justify-content-end': !isTrial() }">

      <p v-if="isTrial()" v-html="$t('TRIAL_MESSAGE')" class="trial-message"></p>

      <div class="d-flex flex-column align-items-end justify-content-center">
        <router-link class="btn btn-outline-blue btn-change-plan" target="_self" :to="`/plans${partner_url}`">{{
          $t('CHANGE_PLAN') }}</router-link>
        <a class="btn-cancel-order mt-3 mr-2" @click.stop="confirmCancelOrder()"><i class="far fa-trash-alt mr-2"></i>{{
          $t('CANCEL_ORDER') }}</a>
      </div>
    </div>


    <b-modal ref="modal-cancel-order" hide-footer :title="modal_cancel_order.title"
      :header-bg-variant="modal_cancel_order.headerBgVariant"
      :header-text-variant="modal_cancel_order.headerTextVariant">

      <p class="my-4">{{ modal_cancel_order.message }}</p>

      <div class="mt-3 d-flex flex-row justify-content-end">
        <b-button class='mr-2' @click="closeModal()">{{ $t('NO') }}</b-button>
        <b-button variant="danger" @click="cancelOrder()">{{ $t('YES') }}</b-button>
      </div>

    </b-modal>


  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import $ from 'jquery'

import Range from '../../plans/checkout/range/Range'

export default {
  components: {
    Range
  },
  props: [
    'selectedPlan',
    'renderedPlan',
    'renderedAddons',
    'lang',
    'partner_url',
    'user_monetary',
    'method'
  ],
  computed: {
    ...mapState('MainStoreModule', {
      env: state => state.env,
      pre_customer: state => state.pre_customer
    }),
  },
  data() {
    return {
      planAddons: [],
      modal_cancel_order: {
        headerBgVariant: "danger",
        headerTextVariant: "light",
        textVariant: "dark",
        title: this.$t('CANCEL_ORDER'),
        message: this.$t('CANCEL_ORDER_MESSAGE')
      }
    }
  },

  mounted() {
    for (var i in this.selectedPlan.addons) {
      this.planAddons.push(this.selectedPlan.addons[i].addon_id)
    }
  },

  methods: {

    ...mapActions('MainStoreModule', [
      'setPreCustomer'
    ]),

    isTrial() {
      const is = this.pre_customer.isTrial == '1' || this.pre_customer.isTrial === true
      return is
    },

    closeModal() {
      this.$refs['modal-cancel-order'].hide()
    },

    confirmCancelOrder() {
      this.$refs['modal-cancel-order'].show()
    },

    cancelOrder() {

      const pre_customer = { ...this.pre_customer }

      this.setPreCustomer(null)
      localStorage.removeItem(this.env)

      if (pre_customer.partner_id) {
        this.$router.push({
          name: "home-partner",
          params: {
            partner_id: pre_customer.partner.id,
            partner_slug: pre_customer.partner.slug
          }
        })
      } else {
        this.$router.push({
          name: "home"
        })
      }

    },

    showAddon(addon, index) {

      var limit = 4
      return limit >= index && this.planAddons.includes(addon._id) && !addon.isOptional

    }
  }


}
</script>

<style lang="scss" scoped>
.ct-form-checkout {
  width: 100%;

  .row {
    margin: 0;
  }
}

.btn-cancel-order {
  cursor: pointer;
  font-size: 10px;
  color: #4D4D4D;
  transition: all .4s ease;

  &:hover {
    color: #FF7A7A;
  }
}


.block-plan {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  h2 {
    color: #4D4D4D;
    font-size: 25px;
    font-weight: bold;
    margin: 0 !important;
  }

  img {
    height: 100px;
    margin: 20px 0;
  }

}

.block-some-addons {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      padding: 0;
      margin: 0;
      color: #4D4D4D;
      font-size: 18px;
      font-weight: bold;

      i {
        margin-right: 10px;
        font-size: 18px;
        color: #3150E6;
      }
    }
  }

}

.block-pricing {

  width: 220px;

  .line-01,
  .line-02,
  .line-03 {
    width: 100%;
    text-align: center;
  }

  .line-01 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    label {
      font-size: 15px;
      font-weight: 600;
      text-decoration: line-through;
      color: #FF7A7A;
      margin: 0;
    }

    span {
      border-radius: 1vw;
      background-color: #FF7A7A;
      padding: 0 5px;
      font-size: 15px;
      font-weight: 600;
      color: #fff;
      width: 60px;
    }
  }

  .line-02 {
    margin-top: 0.5vw;
    justify-content: center;

    label {
      font-size: 15px;
      font-weight: 600;
      color: #4D4D4D;
      padding-top: 7px;
    }

    display: flex;
    flex-direction: row;

    div {
      display: flex;
      flex-direction: column;

      h2 {
        font-size: 70px;
        line-height: 70px;
        font-weight: 700;
        color: #4D4D4D;
        margin: 0 0 0 10px;
        padding: 0;
      }

    }
  }

  .line-03 {
    label {
      font-size: 12px;
      font-weight: 500;
      color: #4D4D4D;
      padding: 0;
      margin: 0;
    }
  }

}

.btn-change-plan {
  padding: 10px 20px !important;
}

.trial-message {
  color: #FF7A7A;
  width: 70%;
}
</style>